.sustainability-page-wrapper {
    padding: 7rem 0;
    width: 100%;
    background-color: #f0f0f0;

    .sustainability-layout {
        padding-left: 12rem;
        padding-right: 12rem;
        scroll-margin-top: 10rem;

        @media (min-width: 2000px) {
            padding-left: 20rem;
            padding-right: 20rem;
        }

        @media (min-width: 1800px) {
            padding-left: 15rem;
            padding-right: 15rem;
        }

        @media (max-width: 1521px) {
            padding-left: 10rem;
            padding-right: 10rem;
        }

        @media (max-width: 1050px) {
            padding-left: 5rem;
            padding-right: 5rem;
        }

        @media (max-width: 500px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .section-heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 0.5rem;
        text-align: center;
    }

    .sustainability-section-content {
        padding-top: 3rem;
        padding-bottom: 3rem;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(29%, 1fr));

        @media (max-width: 1521px) {
            width: 100%;
            box-sizing: border-box;
        }

        @media (max-width: 1050px) {
            padding-bottom: 2%;
            grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
        }

        @media (max-width: 800px) {
            padding-bottom: 1rem;
            flex-direction: column;
            align-items: center;
            column-gap: 2rem;
            grid-template-columns: repeat(auto-fill, minmax(78%, 1fr));
            gap: 3rem;
            justify-items: center;
        }

        @media (max-width: 500px) {
            grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
        }

    }

    .remaining-2 {
        width: 100%;
        box-sizing: border-box;
        padding-top: 0rem;
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
        column-gap: 2rem;

        @media (max-width: 1521px) {
            width: 100%;
            box-sizing: border-box;
        }

        @media (max-width: 800px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            column-gap: 2rem;
            padding-bottom: 3rem;
        }

    }

    .remaining-1 {
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
        column-gap: 2rem;

        @media (max-width: 1050px) {
            grid-template-columns: repeat(auto-fill, minmax(95%, 1fr));
        }

        @media (max-width: 800px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            column-gap: 2rem;
            padding-bottom: 3rem;
        }

        .card {
            box-sizing: border-box;
            width: 100%;

            @media (max-width: 500px){
                width: 90%;
            }
        }

    }

    .heading-img-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 1rem;

        img {
            width: 3rem;
            height: auto;
            color: white;
        }
    }

    .sustainability-page-header {
        width: 100%;
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .sustainability-headings {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 2rem;
            text-align: center;

            h3 {
                text-align: center;
            }
        }
    }

    .section-content-planet {
        background-color: #147350;
        color: white;
        padding-top: 2rem;

    }

    .section-content-people {
        background-color: #FF6C00;
        color: white;
        padding-top: 2rem;
    }

    .section-content-product {
        background-color: #EE2526;
        color: white;
        padding-top: 2rem;
        min-height: 25rem;
        display: flex;
        flex-direction: column;
        row-gap: 5rem;



        .sustainability-section-content {
            justify-content: center;
        }

    }

    .section-content-initiatives {
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 3rem;
    }
}