.faqsContainer{
    margin-top: 10rem;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 8rem;
    min-height: 100vh;
}
.faqsContainer> h1{
    font-weight: 1000;
    font-size: 2.5rem;
}
.faqsHeading{
    height: 2rem;
}
.faqsTabHeading{
    font-weight: 1000;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 1rem;
    margin-bottom: 0rem;
}
.faqsTabHeading > h3{
    font-size: 1.2rem;
}
.faqsTabContent{
    height: 0px;
    overflow: hidden;
    max-width: 70rem;
    font-weight: 500;
    margin-top: -1.3rem;
    margin: 1rem;
}
.show{
    height: auto;
    max-height: 9999px;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
}
.faqsLink{
    color: darkgreen;
    font-weight: 500;
}