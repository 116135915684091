.assetsLayout{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7fafc;
    position: relative;
}
.newsFeedLayout{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 8rem;
    padding-top: 3rem;
    padding-bottom: 8rem;
    background-color: #f7fafc;
}
.badge-padding{
    padding-top: 4.5rem;
}
.assetsContainer{
   max-width: 20%;
   float: left;
   display: flex;
   justify-content: center;
}
.newsFeedContainer{
    max-width: 50%;
}
.pendingPostsContainer{
    max-width: 20%;
}

.asset-sidebar{
    width: 280px;
    min-height: 40rem;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1;
    transition: 0.3s;
    border: 1px solid #e0dede;
    border-radius: 10px;
    overflow-x: scroll;
}
.asset-sidebar.active{
    left: 0;
    top: 8.1rem;
}
.asset-sd-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px 0 10px;
}
.asset-sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
}
.asset-sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}
.asset-sd-body{
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: scroll;
}

@media (min-width: 1521px){
    .assetsLayout{
        padding-left: 15vw ;
        padding-right: 15vw ;
    }
}
@media (min-width: 2130px){
    .newsFeedContainer{
        max-width: 55%;
    }
}
@media (max-width: 1400px){
    .assetsLayout{
        padding-left: 5vw ;
        padding-right: 5vw ;
    }
}
@media(max-width:2130px){
    .newsFeedContainer{
        max-width: 65%;
    }
}
@media(max-width:1800px){
    .newsFeedContainer{
        max-width: 60%;
    }
}
@media(max-width:1700px){
    .newsFeedContainer{
        max-width: 60%;
    }
}
@media(min-width:1500px){
    .newsFeedLayout{
        padding-left: 5rem;
        padding-right: 5rem;
    }
}
@media(max-width:1500px){
    .newsFeedLayout{
        padding-left: 5rem;
        padding-right: 5rem;
    }
}
@media (max-width: 1100px) {
    .newsFeedLayout{
        padding-left: 1rem;
        padding-right: 5rem;
    }
}
/* @media(max-width:800px){
    .newsFeedLayout{
        padding-left: 3rem;
        padding-right: 3rem;
    }
} */
@media(max-width:1521px){
    .newsFeedContainer{
        max-width: 60%;
    }
}
@media(max-width:1250px){
    .newsFeedContainer{
        max-width: 59%;
    }
}
@media(max-width:1100px){
    .newsFeedContainer{
        max-width: 57%;
    }
    .newsFeedLayout {
        width: 100%;
        display: flex;
    }
}
@media(max-width:980px){
    .newsFeedContainer{
        max-width: 60%;
    }
    .assetsContainer{
        max-width: 23%;
     }
}
@media(max-width:900px){
    .newsFeedContainer{
        max-width: 60%;
    }
}
@media(max-width:820px){
    .newsFeedContainer{
        max-width: 60%;
    }
}
@media(max-width:800px){
    .newsFeedContainer{
        max-width: 60%;
    }
}
@media(max-width:450px){
    .newsFeedLayout{
        padding-left: 0rem;
        padding-right: 0rem;
    }
}
@media(max-width:640px){
    .assetsContainer{
        display: none;
    }
}