@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&family=Roboto+Condensed:wght@400;700&family=Roboto+Mono:wght@400;500;600;700&display=swap');
  .main-footer {
    color: white;
    background-color: black;
    position: relative;
    bottom: 0;
    width: 100%;
    z-index: 0;
  }
  .container-global-sites{
    background-color: #147350;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .global-sites{
    width: 30%;
    min-width: 20rem;
    display: flex;
    align-items: center;
    border-width: 60%;
  }
  .global-sites #standard-basic-label{
    color: white;
    font-size: 1.5rem;
  }
  .white-underline:before {
    border: 2px solid transparent !important; 
  }
  .white-underline::after {
    border: 2px solid transparent !important; 
  }
  .MuiInputBase-input-MuiInput-input{
    height: 2em !important;
  }
.global-sites label{
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
}

  .container-footer{  
    padding: 3em 7.5rem 0;
    margin-top: -40px;
  }
  .container-row-logo{
    position: relative;
  }
  .logo-footer{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 1.5rem;
  }
  
  .logo-footer img{
    display: block;
    text-align: left;
    height: 6rem;
    width: 7rem;
    padding-left: 0;
    margin-left: 0.1rem;
    margin-top: 0.875rem;
    margin-bottom: 0.75rem;
  }

  .row-footer{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-top: 2rem;
    margin-right: 6rem;
  }

  .col-footer{
    padding: 0 0 0;
    list-style: none;
    text-align: left;
  }

  .col-footer h4{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.4rem;
    font-weight: 800;
    color: #d5d5d5;
  }
  .col-footer-sm{
   text-align: left;
   font-size: 0.75rem;
   display: block;
  }

  .col-footer-sm .term-conditions {
    align-items: center;
  }

  .row1 p{
    text-align: center;
    margin: 0.2rem 0 0;
    color: rgb(228, 236, 236);
    font-size: 0.75rem;
    font-family: Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",sans-serif;
  }

  .col-footer-sm a{
    font-size: 0.7rem;
    margin-right: 0.3rem;
    text-decoration: none;
    padding-right: 0.5rem;
    color: rgb(229, 241, 241);
  }

  .row1 .col-footer-sm{
    text-align: left;
  }

  .row1 .col-footer-sm1{
    font-size: 0.6rem;
    padding-bottom: 1.7rem;
    text-align: left;

  }
  .references{
    list-style: none;
    padding-left: 0;
    margin-top: 1rem;
  }

  .references li{
    color: rgba(255, 255, 255, 0.7);
    position: relative;
    font-size: 0.8rem;
    line-height: 1.25;
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .references a{
    font-family: Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",sans-serif;
    color: #d5d5d5;
    list-style: none;
    margin-bottom: 0;
    text-align: left;
    text-decoration: none;
  }

 
  .footer-links {
    display: flex;
    align-items: center;
  }

  .footer-links :hover{
    text-decoration: none;
    filter: brightness(150%);
  }
  .Social-Media-TnC-Container {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Social-Media {
    display: flex;
    flex-direction: row;
    float: right;
  }
  .social-links>a{
    background-color: white;
    padding: 0.4rem;
    border-radius: 50%;
  }

  .social-links>a{
    margin: 0.25rem;
    margin-top: 0rem;
    text-decoration: none;
    color: aliceblue; 
  }

  .social-links>a:hover{
    color: #147350;
  }
  
  @media(max-width:1266px){
    .container-footer{
      padding: 3em 6.5rem 0 6.5rem;
    }
  }

  @media(max-width:1025px){
    .container-footer{
      padding: 3em 6.5rem 0 6.5rem;
    }
  }

  @media(max-width:769px){
    .row-footer{
      padding-left: 0;
    }.container-footer{
      padding: 3em 4rem 0 4rem;
      font-size: smaller;
    }
    .logo-footer img{
      height: 5rem;
     width: 5.5rem;
    }
  }

  @media(max-width:700px){
    .row-footer{
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items: left;
      flex-wrap: wrap;
      scroll-behavior: smooth;
      
    }
    .col-footer{
      width: 28%;
    }
    .logo-footer{
      display:flex;
      justify-content: center;
      text-align: center;
    }
    .logo-footer img{
      height: 5rem;
     width: 5.5rem;
    }
    .container-footer {
      padding: 3em 3.5rem 0;
  }
  }

  @media(max-width:500px){
    .row-footer{
      display: flex;
      flex-direction:row;
      padding: 0 0 0 10%;
      align-items: center;
      flex-wrap: wrap;
      scroll-behavior: smooth;
      margin-bottom: 1.5rem;
    }

    .col-footer{
      width: 40%;
      height: 13rem;
      justify-content: center;
      align-items: center;
      font-size: .5rem;
      line-height: 1;
    }
    
    .last-col-footer{
      padding-left: 4rem;
    }
  }

  @media(max-width:447px){
    .row-footer{
      padding-left: 0;
    }
    .last-col-footer{
      padding-left: 3rem;
    }
    .container-footer{
      padding: 3em 2.4rem 0 2.4rem;
    }
    .logo-footer img{
      padding-left: 3.5rem;
    }
    .col-footer h4 {
      font-size: 1.2rem;
  }
  .logo-footer img{
    display: block;
    text-align: left;
    height: 4.5rem;
    width: 5rem;
    padding-left: 0;
    margin-left: -0.5rem;
    margin-top: -0.125rem;
    margin-bottom: 0.75rem;
  }
  .col-footer{
    height: 9rem;
  }
  .row-footer{
    margin-bottom: -3.5rem;
  }
  }

  @media(max-width:376px){
    .container-footer{
      padding: 3em 2.5rem 0 2.5rem;
    }
    .row-footer{
      padding-left: 0;
    }
    .global-sites{
      min-width: 15rem;
    }
  }