.terms{
    margin-bottom: 5rem;
}
.terms a{
    color: #008062;
}
hr{
    color: rgb(229 226 226);
}
.terms h1{
    font-size: 2.7rem;
}
.termsBanner{
    min-height: 17rem;
    background-color: #147350;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    font-size: 4rem;
    font-weight: 900;
}
.termsBanner > p{
    padding-top: 12rem;
    padding-bottom: 2.5rem;
}
.termsBody{
    margin-left: 20rem;
    margin-right: 20rem;
    margin-bottom: 7rem;
    display: block;
    justify-content: center;
}
.privacyH5{
    display: inline;

}
.addressMail{
    margin: 0rem;
    padding: 0rem;
    margin-left: 2rem;
    
}
.termsTableOfContent{
    margin-top: 4rem;
}
.termsTableOfContent > p{
    margin: 1rem;
}
.termsPart1 > p{
    margin: 1rem;
}
.termsPart1 > h1{
    margin: 1rem 0rem;
}
.privacyContentLinkName{
    margin: 0.5rem;
}
@media(min-width: 300px){
    .termsBody{
        margin: 1rem;
        margin-top: 5rem;
        margin-bottom: 7rem;
    }
    .termsBanner{
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0rem;
        margin-top: -1.5rem;
        font-size: 3.5rem;
    }
    .privacyContentLink{
        margin-left: 1.5rem;
    }
}
@media(min-width: 450px){
    .termsBody{
        margin: 3rem;
        margin-top: 6rem;
        margin-bottom: 8rem;
    }
    .termsBanner{
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0rem;
        margin-top: -1rem;
        font-size: 3.5rem;
    }
}
@media(min-width: 576px){
    .termsBody{
        margin: 5rem;
        margin-top: 6rem;
        margin-bottom: 8rem;
    }
    .termsBanner{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0rem;
        font-size: 3.5rem;
    }
}
@media(min-width: 760px){
    .termsBody{
        margin: 10rem;
        margin-top: 6rem;
        margin-bottom: 8rem;
    }
    .termsBanner{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1rem;
        font-size: 3.5rem;
    }
}
@media(min-width: 990px){
    .termsBody{
        margin: 15rem;
        margin-top: 6rem;
        margin-bottom: 10rem;
    }
    .termsBanner{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0rem;
        font-size: 4rem;
    }
}