$listHeadingColor: #656565;
$primaryColorGreen: #147350;

.landing-page-wrapper {
    margin-bottom: 10rem;
    margin-top: 10rem;
    padding: 0 5rem;

    .intro-wrapper{
        width: 100%;
        min-height: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;   
        justify-content: space-evenly;

        .logo-container{
            display: flex;
            justify-content: center;

            .logo {
                align-self: center;
                width: 23%;
                scale: 1.4;
                position: relative;
                margin: 0 15px 0;
            }
        }
        .intro-text-container{
            display: flex;
            flex-direction: column;
            align-items: center;

            h1{
                font-size: 50px;
            }
            h3{
                font-size: 22px;
                color: #147350;
            }
        }
    }
    .contact-form-container{
        margin-top: -5rem;
    }
    .linkedin-container{
        display: flex;
        justify-content: center;
        
        h3{
            font-size: 22px;
        }
        .custom-link{
            text-decoration: none;
            color: black;
            cursor: pointer;

            :hover{
                filter: brightness(1.1);
            }
        }
        .linkedin-logo{
            color:#0077B5;
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
        }
        h3:hover {
            text-decoration: underline;
        }
    }
    
}