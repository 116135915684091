.view-presence-list-btn {
    background-color: #147350 !important;
    border-radius: 2rem;
    top: -8rem;
}

.view-list-container {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;

    .countries-popup-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;

        a {
            text-decoration: none;
            color: #147350;
        }
    }

    .disclaimer-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;
    }
}