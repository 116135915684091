$listHeadingColor: #656565;
$primaryColorGreen: #147350;
.modalWrapper{
	position: fixed;
	top: 0rem;
	left: 0rem;
	right: 0;
	bottom: 0;
	display: none;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9;

	&.showModal{
		display: flex;
	}
}

.modalBody{
	max-width: 45vw;
	max-height: 90vh;
	min-width: 27rem;
	overflow-y: scroll;
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	background-color: white;
	border-radius: 0.5rem;
	padding: 0px 1rem;
	padding-top: 2px;
	width: auto;
	height: auto;

	.formWrapper{
		display: flex;
		justify-content: center;
		margin-bottom: 1rem;
		padding-top: 1rem;

		.formContainer {
			width: 100%;
			margin: 1rem 2rem;

			.twoFieldRow {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.submitBtn-disabled{
				width: 15%;
				height: 2.5rem;
				padding: 10px;
				border: none;
				background-color: #656565;
				color: white;
				font-weight: bold;
				font-size: 1rem;
				margin-top: 15px;
				margin-bottom: 30px;
				float: right;
				border-radius: 2rem;
			}
			.submitBtn{
				width: 15%;
				height: 2.5rem;
				padding: 10px;
				border: none;
				background-color: $primaryColorGreen;
				color: white;
				font-weight: bold;
				font-size: 1rem;
				cursor: pointer;
				margin-top: 15px;
				margin-bottom: 30px;
				float: right;
				border-radius: 2rem;
			}

			.text-button{
				background: none;
				border: none;
				padding: 0;
				margin: 0;
				font-size: inherit;
				color: black;
				text-decoration: underline;
				cursor: pointer;
				margin-top: 15px;
				margin-bottom: 30px;
				margin-right: 1rem;

				&:hover{
					color: blue;
				}
			}

			.btn-container{
				display: flex;
				justify-content: flex-end;
			}
		}
	}


	.twoFieldRow> .inputLabelWrapper{
		width: 48%;
	}

	.inputLabelWrapper{
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;

		.astrixWrapper{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			width: 99%;
			margin-top: 0.5rem;

			.inputLabel{
				font-size: 1rem;
			}
			.astrix{
				color: red;
			}
		}

		.descriptionAstrixWrapper{
			margin-bottom: 0.8rem; 
			position: relative;
		}

		.astrixContainer{
			position: absolute;
			right: 0;
			top: 50%;
		}

		.careerFormInput{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;

			.formInput{
				padding: 15px;
				margin: 10px 0px;
				border-radius: 5px;
				border: 1px solid #c8c8c9;
				font-size: 1rem;
			}
			.formInputError{
				min-height: 2rem;
				font-size: 12px;
				padding: 3px;
				color: red;
				display: none;
				margin-top: -0.7rem;
				margin-bottom: 0.7rem
			}
			input:invalid[focused="true"]{
				border: 1px solid red;
			}
			input:invalid[focused="true"] ~ span{
				display: block;
			}
			.ck-content.ck-editor__editable{
				max-height: 15rem;
			}
		}
		.url-input{
			min-height: 7rem;
		}
	}

	.confirmationContainer{
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin: 1rem 0rem;

		.confirmationText{
			text-align: center;
			font-weight: 800;
			font-size: 22px;
			margin-top: 0.5rem;
			margin-bottom: 0.7rem; 

			.confirmHeader{
				color: black;
				line-height: 1.3;
				margin: 0 0 0.7rem 
			}

			.confirmMessage{
				color: #959292;
				font-size: 0.83em;
				line-height: 1.8rem;
				margin: 0 0 -0.5rem 
			}
		}

		.confirmationBtnContainer{
			display: flex;
			justify-content: center;
			margin: 0 0 -1rem;

			.confirmBtn{
				width: 25%;
				height: 3rem;
				padding: 10px;
				border: none;
				background-color: #147350;
				color: white;
				font-weight: bold;
				font-size: 1rem;
				cursor: pointer;
				margin-top: 15px;
				margin-bottom: 30px;
				float: right;
				border-radius: 2rem;
			}

			.secondary{
				background-color: rgb(183, 180, 180);
				margin-right: 1rem
			}
		}
	}
}

.iconWrapper{
	position: relative;
	width: 100%;
	padding-right: 1rem
}