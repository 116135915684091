.card-wrapper {
    max-height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  .category-card-img {
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 3px;
    background-color: white;
    position: relative;
    .Badge {
      position: absolute;
      top: 0.8rem; 
      right: 0.25rem;
    }
    .card-img {
      width: 99.5%;
      height: 99.5%;
      object-fit: contain;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
  
  .category-card-name-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 8px;
  }
  
  .category-card-name {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.15px;
  }
  .category-card-price-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .category-card-price,
  .category-card-srp {
    font-size: 14px;
    color: #333;
  }
  
  .edit-button {
    background: none;
    border: none;
    color: green;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0;
  
    &:hover {
      text-decoration: underline;
    }
  }
  