.container1-h1{
  position: relative;
  top: 10rem;
  height: 100vh;
  text-align: center;
}
.folder-container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 10rem;
    padding: 1rem;
}
.folder-container h1{
  top: 100px;
    position: relative;
    margin: 0rem 0 7rem;
    padding: 1rem;
    font-size: 4rem;
  opacity: 15%;
}
.location {
  width: 60%;
}
.folder-container h4{
  margin: 0;
  position: relative;
  font-size: 1.1rem;
  text-decoration: none;
}
.table-container{
  width: 60%;
  height: 450px;
  overflow: scroll;
}
  .table-assets {
    border-collapse: collapse;
    width: 100%;
  }
  
  .th-assets,
  .td-assets {
    border: 1px solid #ffffff;
    text-align: left;
    padding: .5rem 1rem;
    font-size: 1rem;
  }
  .file-icon{
    padding: 0 0.8rem 0 0.4rem;
  }
  .th-assets {
    background-color: rgb(31 120 89);
    color: white;
    position: sticky;
    top: 0;
}
  
  .td-assets {
    background-color: rgb(246 246 246);
  }

  .download{
    text-align: center;
  }
  .td-assets a{
    color: #014841;
    text-decoration: none;
  }

  .td-assets a:hover{
    color: rgba(10, 58, 42, 0.9);
    text-decoration: underline;
  }
  h4 a {
    color: #147350;
    text-decoration: none;
  }
  h4 a:hover {
    text-decoration: underline #147350;
    filter: brightness(1);
    color: #147350;
}
h4 svg{
  font-size: 2rem;
    color: darkgray;
    position: relative;
    top: 0.63rem;
    margin: 0 .5rem;
}
@media (max-width: 546px) {
  .table-container{
   width: 80%;
   margin-left: -2rem;
 }
 .locationContainer{
   width: 80%; /* same as table width */
   margin-left: -2rem;
 }
 .folder-container h1{
  font-size: 3rem;
  margin-left: -2rem;
}
 }

@media(max-width: 450px){
  .folder-container h1 {
    font-size: 2.3rem;
}
.folder-container h4 {
  font-size: .9rem;
}
h4 svg {
  font-size: 1.2rem;
  top: 0.32rem;
  margin: 0 0.2rem;
}
}