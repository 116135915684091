@media (min-width: 1521px){
    .footerLayout {
        padding-left: 8vw;
        padding-right: 8vw;
    }
}
@media (min-width: 2403px){
    .footerLayout {
        padding-left: 16vw ; 
        padding-right: 16vw ;
    }
}
@media (min-width: 3125px){
    .footerLayout {
        padding-left: 20vw ; 
        padding-right: 20vw ;
    }
}
@media (min-width: 3750px){
    .footerLayout {
        padding-left: 21vw ; 
        padding-right: 21vw ;
    }
}