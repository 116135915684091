.sidebar{
    width: 280px;
    min-height: 40rem;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 1;
    transition: 0.3s;
    border: 1px solid #e0dede;
    border-radius: 10px;
    overflow-x: scroll;
}
.sidebar.active{
    right: 0;
    top: 8.1rem;
}
.sd-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px 0 10px;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}
.sd-body{
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: scroll;
}
