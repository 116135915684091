@import url('./styleUtils/style.css');
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* CKEditor Customization */
.ck.ck-balloon-panel.ck-powered-by-balloon[class*=position_border] {
  display: none;
}

.ck.ck-toolbar {
  border-radius: var(--ck-border-radius) var(--ck-border-radius) 0 0 !important;
}
.ck-editor__editable_inline {
  border: 1px solid var(--ck-color-toolbar-border) !important;
  border-top-width: 0 !important;
  min-height: 9rem;
  border-radius: 0 0 var(--ck-border-radius) var(--ck-border-radius) !important;
}

.ag-theme-quartz .ag-cell-wrapper {
	padding: 0 1rem;
}
.ag-root-wrapper.ag-layout-normal {
	height: 70vh !important;
}
.ag-checkbox-input-wrapper.ag-checked::after {
    color: #107f62 !important;
  }
.ag-theme-quartz .ag-header {
	border-color: green!important;
}


h1 {
  font-family: 'Right Grotesk';
  font-size: 6rem;
}

h2 {
  font-family: 'Right Grotesk';
  font-size: 3.75rem;
}

h3 {
  font-family: 'Right Grotesk';
  font-size: 3rem;
}

h4 {
  font-family: 'Right Grotesk';
  font-size: 2.125rem;
}

h5 {
  font-family: 'Right Grotesk';
  font-size: 1.5rem;
}

h6 {
  font-family: 'Right Grotesk';
  font-size: 1.25rem;
}