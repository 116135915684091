.search-bar-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  
    .search-icon {
      position: absolute;
      left: 0.5rem;
      color: #888;
      width: 1.25rem;
    }
  
    .search-input {
      padding: 0.8rem 1rem 0.8rem 2rem;
      border: 1px solid rgb(213, 216, 218);
      border-radius: 0.25rem;
      width: 190px;
      outline: none; 
  
      &:focus {
        border-color: rgb(213, 216, 218); 
        box-shadow: none; 
      }
    }
  }
  
   