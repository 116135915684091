.css-1m9128y {
    margin-top: 0;
}

.css-1f8sh1y {
    top: 6.7rem;
}

.css-1l7c0cy {
    top: 50%;
    transform: translateY(-50%);
}

.css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
    color: #147350;
}

.css-1abc02a {
    top: 40%;
    right: 10%;
}

.css-hn784z {
    top: 40%;
    left: 10%;
}

.css-1m9128y {
    bottom: 3.7rem;
}

.css-1ps6pg7-MuiPaper-root {
    background-color: unset !important;
    color: white !important;
  }
  

/* MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium  css-bbreus-MuiButtonBase-root-MuiIconButton-root

MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium  css-8ucetn-MuiButtonBase-root-MuiIconButton-root */