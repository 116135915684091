.custom-modal-wrapper {
    position: fixed;
    top: 0rem;
    left: 0rem;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;

    &.show-modal {
        display: flex;
    }

    .custom-modal-body {
        border-radius: 0.5rem;
        padding: 0;
        padding-top: 2px;
        width: 100%;
        max-width: 80vw;
        position: relative;
        background-color: white;

        .custom-modal-header {
            padding: 0.5rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            background-color: white;
            box-sizing: border-box;
            border-radius: 0.5rem;

            .close-icon {
                color: #8f8f8f;
                cursor: pointer;
            }
        }

        .custom-modal-form {
            display: flex;
            flex-direction: column;
            margin: 0.5rem;
            margin-top: 0;
            padding: 1rem;
            padding-top: 5rem;
            border-radius: 0.5rem;
            max-width: 97%;
            width: auto;
            background: white;
            max-height: 85vh;
            overflow-y: auto;
        }
    }

}