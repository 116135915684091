.common-button-wrapper {
    padding: 0;
    padding-left: 0.7rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    cursor: pointer;

    .MuiButton-contained {
      color: white;
      background-color: #147350;
      border-radius: 2rem;

      &:hover {
        color: white;
        background-color: #147350;
      }
      @media (max-width: 770px) {
        font-size: 0.7rem;
      }
    }

    .MuiSvgIcon-root{
      @media (max-width: 770px) {
        width: 1.1rem;
      }
    }

    @media (max-width: 770px) {
      width: 8rem;
      padding: 0;
      padding-left: 0.7rem;
    }
  }
 