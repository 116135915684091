.text-center{
    text-align: center;
    padding-top: 2rem;
    font-family: 'Right Grotesk';
    background-color: #f0f0f0;
}
.contact-heading{
    font-size: 36px;
    font-weight: 900;
    background-color: '#f0f0f0';
}
.general-questions{
    margin: 0 0 3rem;
}
.flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px 0px 65px 0px;
    background-color: #f0f0f0;
}
.section-content{
    width: 28.3333%;
    padding:0 0px 25px 0px;
    margin-left: 2rem;
}
.section-content-item{
    border-radius: 25px;
    background-color: white;
    padding : 3.125rem 2.5rem;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 10px;
    font-family: 'Right Grotesk';
}
.section-content-item>p{
    margin-top: 0.5rem;
    padding: 0;
}
.contactCard{
    text-decoration: none;
    font-weight:900;
    color:black;
    font-size: 28px;
    font-family: 'Right Grotesk';
}
.contactCard:hover{
    text-decoration: underline;
 
}
.email {
    color: black;
    text-decoration: none;
}
 
.email:hover {
    color: #008755;
    text-decoration: none;
}

@media(max-width:978px){
    .section-content-item{
        padding : 2.125rem 1.5rem;
    }
}
@media(max-width:800px){
    .flex{
        display: flex;
        flex-direction:column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        
    }
    .section-content{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: auto;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
    .section-content-item {
        width: 50%;
        margin: 1rem;
        border-radius: 20px;
        padding : 3.125rem 2.5rem;
    }    
    .container-footer {
        padding: 3em 8rem 0;
    }
 }
