.buttonWrapper {
    border-radius: 5rem;
    overflow: hidden; 

    .button {
      color: white;
      background-color: #147350;
      font-size: 0.9rem;
      transition: background-color 0.3s ease;
    
      &:hover {
        color: white;
        background-color: #147350;
      }
      
      &.hasItems {
        background-color: #147350;
        &:hover {
          background-color: #147350;
        }
      }
    }
    
    .icon {
      font-size: 1.2rem;
      color: inherit;
    }
  }
  
  
  