.container1{
    position: relative;
    top: 7.5rem;
    margin-bottom: 10rem;
    padding: 80px;
    align-items: center;
    justify-content: center;
}
.assetsCont{
    top: 0;
    padding: 0;
    margin-bottom: 0.8rem;
}
.container1-h1{
    position: relative;
    top: 7.5rem;
    height: 100vh;
    text-align: center;
}
.badge-margin{
    margin-top: 3rem;
}
.navlinksAssets{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: stretch;
    /* padding: 13px; */
    margin: -20px -26px -26px -26px;
    margin-left: -16px;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}
.images{
     padding-right: 1rem;
     padding-left: 1rem;
     height: auto;
     width: 1.3rem;
     color: black;
}
.images-1{
    /* padding: 0rem;
    width: 1rem;
    height: 1.3rem;
    margin-left: 1.3rem;
    margin-top: 0.3rem;
    margin-right: 0.5rem; */
    padding-right: 1rem;
    padding-left: 1.2rem;
    height: auto;
    width: 1.1rem;
    color: black;
}
.whiteImages{
    padding-right: 1rem;
    padding-left: 1rem;
    height: auto;
    width: 4rem;
    filter: invert(95%) sepia(70%) saturate(18%) hue-rotate(314deg) brightness(104%) contrast(104%);
}
.icon-name{
    text-align: center; 
    font-size: 1rem;
    margin-top: 0;
    color: black;
    font-weight: 500;
}
.brandStandardIcon{
    filter: invert(29%) sepia(10%) saturate(6389%) hue-rotate(125deg) brightness(91%) contrast(101%);
    width: 20px;
    height: 22px;
}
.socialMediaIcon{
    color: black;
    filter: invert(29%) sepia(9%) saturate(5030%) hue-rotate(191deg) brightness(102%) contrast(87%);
}
.workshopMaterialsIcon{
    filter: invert(33%) sepia(93%) saturate(691%) hue-rotate(173deg) brightness(96%) contrast(95%);
    width: 20px;
    height: 22px;
}
.popSignageIcon{
    filter: invert(35%) sepia(70%) saturate(903%) hue-rotate(173deg) brightness(94%) contrast(93%);
    padding-right: 0.5rem;
    width: 20px;
    height: 20.5px;
}
.trainingMaterialIcon{
    filter: invert(35%) sepia(41%) saturate(1484%) hue-rotate(174deg) brightness(100%) contrast(93%);
    width: 50px;
    height: 13px;
}
.navlinksAssetsItems{
    display: block;
    flex: 1 0 21%;
    border-radius: 5px;
    min-height: 3rem;
    width: 100%; 
}
/* .navlinksAssets :not(:last-child){
    margin: 0.1rem 0;
} */
.navlinksAssetsItems :hover{
    background-color:#ece9e9;
    box-shadow: 2px 2px 2px rgba(33,33,33,.2); 
}
.dAMHeadingContainer{
    margin-bottom: 1rem;
    padding: 0rem;
    border: 0.5px solid #BEBEBE;
    display: flex;
    flex-direction: column;
    float: left;
    background-color: white;
    border-radius: 0.5rem;
    min-width: 20rem;
    border: 1px solid #e0dede;
    margin-right: 2.3rem;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.08);
}
.dAMHeading{
    background: white;
    box-shadow: 0px 2px 2px #f2f2f2;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    position: relative;
    text-align:left;
    /* margin: 1.8rem 1.2rem ; */
    font-size: 0.5rem;
}
.linksToDA{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    height: 3rem;
    padding: 0.3rem 0 0.3rem 0;
    margin: 0.5rem;
}
.linksToDA :hover{
    background-color: transparent;
    box-shadow: none;
}
.popSignage{
    padding-left: 0.5rem;
}
@media (max-width: 1350px){
    .dAMHeadingContainer{
        min-width: 17rem;
        margin-right: 0.3rem;
    }
}
@media (max-width: 1200px){
    .dAMHeadingContainer{
        min-width: 14rem;
    }
}
/* @media(max-width:800px){
    .dAMHeading{
        top: 10rem;
        position: relative;
        padding-bottom: 4.5rem;
    }
} */
@media(max-width:535px){
    .dAMHeading{
        top: 10rem;
        text-align: center;
    }
}
@media(max-width:755px){
    .dAMHeadingContainer{
        min-width: 11rem;
    }
    .icon-name {
        font-size: 0.7rem;
    }
}
@media(max-width:545px){
    .dAMHeadingContainer{
        min-width: 16rem;
    }
    .icon-name {
        font-size: 1rem;
    }
}