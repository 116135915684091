.page-section-header {
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 8rem;
    margin-bottom: 1rem;

    @media (max-width: 500px) {
        min-height: 5rem;
    }

    a {
        text-align: center;
        text-decoration: none;
        font-family: Right Grotesk;
        font-size: 1.5rem;
        color: #00000099;
        padding: 2rem;
        padding-top: 1rem;
        padding-bottom: 0.25rem;

        &:hover {
            color: #147350;
        }

        @media (max-width: 500px) {
           font-size: 1rem;
           padding: 0.5rem;
        }
    }

    .active {
        color: #147350;
        border-bottom: 3px solid #147350;
    }
}