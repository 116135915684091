.container2{
    overflow-x: hidden;
    height: 100vh;
}
.title{
    padding:25px;
    align-items: center;
    justify-content: center;
    font-size: 45px;
}
.icons{
    width: 40px;
    height: 40px;
    color: gray;
    padding: 10px;
}

.icons:hover{
    cursor: pointer;
    color: black;
}
.heading{
    text-decoration: none;
    color: rgb(74, 74, 74);
    padding-top:0px ;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-top: 0px;
}

.navlinkFirst{
    display: flex;
    flex-direction: row;
  flex-wrap: wrap;
    justify-content:space-evenly;
    align-items: flex-start;
    padding: 40px;
    margin:0;
}