.nav {
    position: fixed;
    width: 100%;
    height: 7.4rem;
    z-index: 2;
}

.second-nav {
    position: relative;
    font-family: 'Roboto Condensed', sans-serif;
    margin: 0;
    background-color: white;
    height: 5.5rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem; 
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.152);
}

.navigation {
    width: 60%;
    position: relative;
}

.navigation-items {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.navigation-items a:active{
    color:#147350 ;
    border-bottom: 3px solid #147350;
}

.logo-nav {
    position: relative;
    display: inline;
    padding: 0;
    margin: 0;
    margin-left: 1rem;
    width: 15%;
    align-items: center;
}

.logo {
    align-self: center;
    /* width: 23%; */
    scale: 1.4;
    position: relative;
    margin: 0 15px 0;
}
.logo:hover {
    filter: brightness(0.8);
}
.stripes{
    width: 25%;
    position: relative;
    margin-bottom: 5px;
}

.buttons {
    position: relative;
    font-size: 1.1rem;
    font-weight: bolder;   
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: none;
    color: black;
    letter-spacing: 0px;
    padding: 20px;
    transition: all 150ms;
}

.buttons:hover {
    text-decoration: none;
    color: #006f46 !important;
}
.nav-link{
    text-align: center;
    text-decoration: none;
    line-height: 32px;
    padding: 20px;
    color:black;
    font-weight: bold;
  }
  .nav-link:active:hover{
      cursor: pointer;
      color:#147350;
      text-decoration: none;
  }

  .nav-link:active,
  .nav-link.active
  {
      color:#147350 ;
      border-bottom: 3px solid #147350;
  }
.login-button-container,
.user-container{
    background-color: rgba(242, 242, 242, 0.417);
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: .5px solid rgba(57, 57, 57, 0.589);
    cursor: pointer;
}
.user-container{
    outline: .5px solid rgba(26, 26, 26, 0.604);
}
.login-icon,
.user-icon{
    font-size: xx-large;
    font-weight: 100;
    opacity: .4;
    padding: .5rem;
}
.user-icon{
    opacity: .7;
}
.login-contact-button {
    display: flex;
    position: relative;
    justify-content: center;
}

.contact-button {
    font-family: 'Roboto Condensed', sans-serif;
    border-radius: 5rem;
    padding: 0.7rem 1.6rem;
    border: none;
    font-weight: 900;
    text-decoration: none;
    font-size: medium;
    background-color: #147350;
    color: white;
    cursor: pointer;
    align-items: center;
    margin: 20px;
    transition: all 150ms;
}

.contact-button:hover {
    filter: brightness(1.2);
}

.mobile-navigation {
    display: none;
}

.mobile-navbar {
    display: none;
}

.mobile-nav_items {
    display: flex; 
    justify-content: space-between;  
}

.mobile-nav_arrow {
    color: black;
    padding-top: 0.2rem;
}

.drop-down_nav {
    position: absolute;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    background-color: #147350;
    height: 10rem;
    width: 100%;
    top: 7.4rem;
}

.drop-down_nav a {
    font-size: x-large;
    font-weight: 900;
    color: white;
}

.drop-down_icon {
    position: absolute;
    bottom: 35%;
    color: black;
    size: 20px;
}

.drop-down_mobile-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.drop-down_mobile-nav a {
    padding-left: 2rem;
    font-size: x-large;
    filter: brightness(2.2);
}
@media(max-width: 1110px){
    .navigation {
        width: auto;
        position: relative;
    }
}

@media (max-width: 1026px) {
    .navigation-items {
        justify-content: right;
    }
}

@media (max-width: 930px) {
    .navigation {
        display: none;
    }
    .drop-down_nav {
        display: none !important;
    }
    
    .mobile-navigation {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
    }

    .mobile-navbar {
        max-height: 25rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        top: 6rem;
        background-color: #147350;
    }

    .Hamburger {
        cursor: pointer;
    }

    .buttons {
        color: white;
        font-size: large;
        padding: 1rem 1.5rem;
    }
    .buttons:hover {
        color: white !important;
    }
    .login-contact-button {
        transform: translateY(-15%);
    }
    .contact-button {
        border-radius: 2rem;
        font-weight: 900;
        font-size: large;
        background-color: white;
        color: #147350;
        cursor: pointer;
        margin: 0 1rem 1rem;
        position: relative;
        text-align: center;
    }
    .contact-button:hover {
        background-color: azure;
    }
    .hideForMV{
        display: none !important;
    }
    .logo-nav {
        margin-left: 0.6rem;
    }
}

@media (max-width: 500px) {
    .second-nav {
        display: fixed;
        height: 4rem;
    }
    .logo {
        /* width: 22%; */
        scale: 1;
        margin: 0;
    }
    .stripes {
        top: -0.55rem;
        height: 41px;
        width: 17%;
    }
    .mobile-navbar {
        top: 6.4rem;
    }
    .buttons {
        font-size: large;
        align-self: center;
    }
    .nav-link:active:hover{
        color:black;
        text-decoration: none;
    }
    .nav-link:active,
  .nav-link.active
  {
      color:black ;
  }
    .contact-button {
        font-size: large;
    }
    .drop-down_mobile-nav a {
        font-size: large;
    }
    .logo-nav {
        margin-left: 0.5rem;
    }
}

@media (max-width: 401px) {
    /* .logo-nav {
        width: 14rem;
    } */
    .logo {
        top: 6px;
        /* width: 27%; */
        height: 60px;
    }
    .stripes{
        top: -0.1rem;
    height: 35px;
    width: 19%;
    }
    
}

/* @media (max-width: 300px) {
    .logo-nav {
        width: 13rem;
    }
} */

@media print {
    .nav {
      display: none;
    }
}
