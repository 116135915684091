.dropzone-wrapper {
    .drop-wrapper {
      position: relative;
      min-width: 150px;
      width: 30rem;
      min-height: 50px;
      height: 220px;
      border: 1px dashed rgb(215, 214, 214);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0.5rem 0;
      cursor: pointer;
  
      &.disable-cls {
        opacity: 0.4;
        cursor: default;
      }
  
      &:hover {
        background-color: #1473501a;
      }
  
      &.no-hover:hover {
        background-color: transparent;
        cursor: default;
      }
  
      .icon-container {
        display: flex;
        justify-content: center;
        color: #147350;
      }
  
      .text-1,
      .text-2 {
        display: flex;
        justify-content: center;
      }
  
      .text-2 {
        font-size: 0.7rem;
        color: gray;
      }
  
      img.uploaded-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
  
      .remove-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        border-radius: 50%;
        cursor: pointer;
        padding: 2px;
        font-size: 1.2rem;
      }
    }
  }
  