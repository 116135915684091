$primaryColorGreen: #147350;

.user-management-container {
	.user-management-header {
		margin-bottom: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		font-family: 'Roboto Condensed', sans-serif;

		.btns-container {
			display: flex;
			justify-content: flex-end;

			.usermanagement-search {
				background-color: white;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 2rem;
				border-radius: 20px;
				box-shadow: 2px;
				padding: 4px;
			 
				input {
					background-color: transparent;
					border: none;
					min-width: 165px;
					width: auto;
				}
				input:focus {
					outline: none;
				}
				input::-webkit-search-cancel-button {
					margin: 8px;
				}
			}

			.add-user-btn {
				font-family: 'Roboto Condensed', sans-serif;
				border-radius: 5rem;
				padding: 0.7rem 1.6rem;
				border: none;
				font-weight: 600;
				text-decoration: none;
				font-size: medium;
				color: RGB(244, 249, 249);
				cursor: pointer;
				margin-left: 10px;
				transition: all 150ms;
				background-color: $primaryColorGreen;
			}
		}
	}
	.MuiDataGrid-columnHeaders {
		border-bottom: 2px solid $primaryColorGreen;
	}
	

	#myGrid {
		width: 100%;
		height:610px;
		position: relative;	
		.user-status{
			border-radius: 2rem;
			padding: 0.1rem 1rem;
			text-align: center;
			color: white;
			&.Active {
				background-color: $primaryColorGreen;
			}
			&.Inactive {
				background-color: #656565;
			}
		}
		.pagination-container {
			display: flex;
			align-items: center;
			span {
				margin-right: .5rem;
			}
			.MuiPagination-root {
				margin-top: 0;
			}
		}
	}
		
}

.base-Popper-root {
	&.MuiDataGrid-panel,
	&.MuiDataGrid-menu {
		z-index: 1 !important;
	}
}

.add-edit-user-container {
	.breadcrum-container {
		display: flex;
		justify-content: flex-start ;
		align-items: baseline ;
		width: 80%;
	}

	.user-status-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 32px;

		.user-status-controls {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-grow: 1;

			button {
				font-family: "Roboto", "Helvetica", "Arial", sans-serif;
				border-radius: 3rem;
				font-weight: 600;
				font-size: small;
				cursor: pointer;
				padding: 10px 20px;
				margin-right: 20px;
				background-color: white;
				&.deactivate-user,
				&.reset-password {
					border: 1px solid $primaryColorGreen !important;
					color: #147350;
				}
				&.delete-user {
					border: none ;
					color: red ;
					margin-left: auto;
				}
				&.delete-user:not(:disabled):hover {
					filter: brightness(1.2);
				}
			}
		}
	}

	.btns-container {
		display: flex;
		justify-content: flex-start;


		button {
			font-family: 'Roboto Condensed', sans-serif;
			border-radius: 5rem;
			padding: 0.7rem 1.6rem;
			border: none;
			font-weight: 600;
			text-decoration: none;
			font-size: medium;
			cursor: pointer;
			margin-left: 10px;
			transition: all 150ms;
			&.confirm-btn {
				background-color: $primaryColorGreen;
				color: RGB(244, 249, 249);
				&:disabled {
					background-color: #656565  !important;
					cursor: not-allowed; 
				}
				&:not(:disabled):hover {
					filter: brightness(1.2);
				}
			}
			&.cancel-btn {
				border: 1px solid $primaryColorGreen !important;
				color: $primaryColorGreen;
				background-color: white;
			}
		}
	}
	.Mui-focused{
		border-color: $primaryColorGreen;
		outline-color: $primaryColorGreen; 
	}

	.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root .MuiOutlinedInput-notchedOutline {
		border-color: $primaryColorGreen;
	}

	.custom-checkbox {
		&.Mui-checked {
		  color: #147350;
		}
	}
}