.bg-home-video-container {
  position:-webkit-sticky;
}

.bg-home-video-container video {
  position: relative; 
  width:100%; 
  height: 100%; 
  object-fit:fill; 
  z-index: 0;
}
.founder-quote{
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.founder-quote > h2{
  margin: 0.5rem 2.5rem;
}
.founder-quote > p{
  margin: 0.1rem;
}
@media(max-width:680px){
  .bg-home-video-container{
    margin-top: 30px;
  }
  .founder-quote > h2{
    text-align: center;
    font-size: medium;
    margin: 0.5rem 2.2rem;
  }
  .founder-quote > p{
    margin: 0.1rem 0.2rem;
    font-size: small;
  }
}

.brandstory-vision, .brandstory-misson {
  text-align: center;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f0f0f0;
}

.global-sites {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.icon {
  color: #147350;
}

.contact-us {
  margin-left: auto;
}

.contact-text {
  text-decoration: none;
  color: black;
  font-weight: 500;
  transition: color 0.2s;
  cursor: pointer;
}

.contact-text:hover {
  color: #008755;
}