.layout-wrapper {
  padding: 10rem 5rem 0rem 5rem;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;

  @media (min-width: 1521px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  @media (max-width: 770px) {
    padding: 10rem 2rem 4rem 2rem;
  }

  .error-helper-text {
    color: #d32f2f;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    letter-spacing: 0.03333em;
  }

  .wrapper {
    width: 100%;
  }

  .collection-layout {
    width: 100%;
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7fafc;


    .card-grid-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4,1fr);
      gap: 3rem;
      .category-card-img{
        height: 85%;

        .card-img{
          width: auto;
          max-width: 90%;
          height: 12rem;
        }
      }

      @media (max-width: 770px) {
        grid-template-columns: repeat(2,1fr);
        gap: 2rem;
      }
    }
  }
}


/* BMHeader styles */
.bm-header-wrapper {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;

  @media print {
    display: none;
  }

  .route-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .route-name {
      >a {
        color: #147350;
        text-decoration: none;
        font-weight: 500;

        @media (max-width: 770px) {
          font-size: 0.8rem;
        }
      }

      >svg {
        font-size: 1.5rem;
        color: darkgray;
        position: relative;
        top: 0.43rem;
        margin: 0 0.5rem;

        @media (max-width: 1050px) {
          margin: 0 0.2rem;
        }

        @media (max-width: 770px) {
          font-size: 0.8rem;
          margin: 0 0.1rem;
          top: 0.2rem;
        }
      }

      .last-breadcrumb {
        color: #626262;
        text-decoration: none;
        pointer-events: none;
      }

      .collection-name-responsive {
        @media (max-width: 770px) {
          font-size: 0.8rem;
        }
      }
    }
  }

  .heading-button-wrapper {
    margin: 1rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;

    .button-wrapper {
      display: flex;
      gap: 1rem;
    }
  }
}
.badge-margin{
  margin-top: 2rem;
}

/* ManagementHeader styles */
.mh-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  margin-top: 1rem;

  .subheading-wrapper {
    display: flex;
    justify-content: space-around;
    padding-top: 1rem;
  }

  .subheading {
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    color: black;
    transition: color 0.3s, border-bottom 0.3s;

    &.active {
      color: #147350;
      border-bottom-color: #147350;
    }

    &:hover {
      color: #147350;
    }

    @media (max-width: 770px) {
      font-size: 0.7rem;
    }

    .MuiSvgIcon-root {
      @media (max-width: 770px) {
        width: 1.1rem;
      }
    }

    @media (max-width: 770px) {
      width: 8rem;
      padding: 0;
      padding-left: 0.7rem;
    }
  }

  .heading-button-wrapper {
    margin: 1rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;

    .button-wrapper {
      display: flex;
      gap: 1rem;
    }
  }
}

/* CreateCollection form styles */
.form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;

  .form-header {
    width: 73%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .form-heading {
      display: flex;
      cursor: pointer;
    }
  }

  .centre-aligned-form-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    padding: 1.25rem;

    .MuiButton-outlined {
      color: #147350;
      border-style: none;
      border: 1px solid #147350;
      padding: 1rem;
    }

    .form-input-heading{
      margin-bottom: 1.25rem;
    }
  }

  .button-container {
    width: 70%;
    display: flex;
    float: left;
    margin: 0;
    margin-left: -2rem;

    .save-btn {
      color: white;

      &:disabled {
        color: rgba(0, 0, 0, 0.26);
        background-color: rgba(0, 0, 0, 0.12) !important;
        cursor: not-allowed;
      }
    }
  }

  .list-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
}

/* LinearListItem styles */
.product-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .product-details {
    display: flex;
    flex-direction: row;
    align-items: center;

    .product-name {
      margin-left: 16px;
      font-size: 1rem;
    }
  }

  .pricing-details {
    display: flex;
    flex-direction: row;
    align-items: center;

    .price,
    .srp {
      margin-left: 16px;
      font-size: 0.875rem;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    .pricing-details {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

/* BrowseModal styles */
.browse-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;

  .browse-modal-content {
    width: 90%;
    max-width: 32rem;
    background-color: white;
    border-radius: 8px;
    padding: 1.5rem;
    position: relative;

    @media (min-width: 600px) {
      width: 75%;
    }

    @media (min-width: 960px) {
      width: 50%;
    }

    .browse-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .MuiTypography-root {
        font-size: 1.25rem;
        font-weight: bold;
      }

      .close-icon {
        color: #8f8f8f;
      }
    }

    .browse-modal-body {
      margin-bottom: 1rem;
      background-color: white;
      border-radius: 8px;
    
      .modal-product-list {
        max-height: 250px;
        height: 250px;
        overflow-y: auto;
      }
    
      .product-item {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        height: 3.5rem;
      }

      .custom-checkbox {
        padding: 0.5rem;
      }

      .product-divider {
        margin-top: 5px;
        margin-bottom: 10px;
      }

      .pagination-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
      }

      .modal-divider {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .custom-checkbox {
        padding: 0.5rem;

        &.Mui-checked {
          color: #147350;
        }
      }
    }
  }
}


.button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;

  .done-btn {
    background-color: #147350;
    color: white;
    margin-right: 0.75rem;
    margin-left: 2rem;
    border-radius: 5rem;
    padding: 0.25rem 0.75rem;

    &:hover {
      background-color: #0f5a3d;
    }
  }

  .cancel-btn {
    color: #147350;
    background-color: white;
    border: 1px solid #147350;
    border-radius: 5rem;
    padding: 0.25rem 0.75rem;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.LinearListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.search-bar {
  margin-bottom: 1rem;
}

.grid-wrapper {
  width: 100%;
}

.active-status {
  border: 1px solid green;
}

.data-grid-header {
  .MuiDataGrid-columnHeader {
    border-bottom: 2px solid #147350 !important;
  }
}

.table-item-image {
  width: 50px;
  max-height: 3.3rem;
  margin: 4px;
}

//CategoryCollectionSection
.collections-section {
  width: 25%;
  background-color: rgb(247, 250, 252);

  .collections-section-header {
    padding-bottom: 10px;
    font-size: x-large;
  }

  .collections-section-container {
    background-color: white;
    padding: 13px;
    min-height: 1.5rem;
    max-height: 37rem;
    overflow-y: auto;

    .collection-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      input[type="radio"] {
        accent-color: #147350;
      }

      label {
        margin-left: 8px;
      }
    }
  }
}

// Collection Product list
.collection-product-list-wrapper {
  display: flex;
  flex-direction: column;
  background-color: rgb(247, 250, 252);
  padding: 10rem 4.4rem 3rem 3.5rem;
  min-height: 75.2vh;

  .content-wrapper {
    display: flex;
    flex: 1;
    gap: 5rem;
  }
  .products-section {
    width: 70%;
    background-color: rgb(247, 250, 252);
    min-height: 100%!important;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .product-cards {
      flex-direction: row;
      -webkit-box-pack: start;
      -webkit-box-align: center;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      scroll-behavior: smooth;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 10rem;
      column-gap: 3rem;
      width: 100%;
      margin-bottom: 10rem;

      @media (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr); 
      }

      .card-img{
        height: 10rem;
        padding: 10% 5%;
      }
    }
    .category-card-name {
      font-size: 1rem! important;
    }

    .product-card-container {
      background: rgb(247, 250, 252);
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;

      .product-details {
        text-align: start;

        .product-price,
        .product-srp {
          font-size: 12px;
          margin: 3px 0;
          color: #333;
        }
      }
    }
  }
}

//add-to-list-button
button.add-to-list-button,
.quantity-controls {
  margin-top: 2px;
  background-color: transparent ;
  color: #147350  ;
  border: 0.5px solid #147350  ;
  padding: 3px 4px;
  border-radius: 3px;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.quantity-controls {
  background-color: #147350;
  color: white;

  .quantity-button {
    background-color: #147350;
    color: white;
    border: none;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 2px;
    min-width: 1.2rem;
    height: auto;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  .quantity-input{
    width: 40px;
    height: 24px;
    text-align: center;
    border: none;
    padding: 0;
    font-size: 14px;
    background-color: #f9f9f9;
    &:focus {
      outline: none;
    }
  }

  .quantity {
    padding: 0;
    font-size: 0.8rem;
    font-weight: 500;
    color: white;
    border:none;
  }
}