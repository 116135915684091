.product-details-page-wrapper {
  display: flex;
  flex-direction: column;
  background-color: rgb(247, 250, 252);
  padding: 10rem 4.4rem 0rem 3.5rem;
  min-height: 75.2vh;
}

#success-mail-container {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'space-evenly';
  height:'100%';
  img {
    width: 96px;
    height: 96px;
    padding: 3px 0;
  }
  p {
    padding: 1rem 0;
    max-width: 70vw;
  }
  Button {
    background-color: #147350;
    width: '200px';
    border-radius: 36px;
    margin: 1rem 0;
  }
}

.product-info-container {
  display: flex;
  border: 1px solid #ddd;
  padding: 1rem 13rem;
  border-radius: 8px;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8rem;

  @media (max-width: 1100px) {
    padding: 1rem 10rem;
  }
  @media (max-width: 930px) {
    padding: 1rem 8rem;
  }
  @media (max-width: 770px) {
    padding: 1rem 6rem;
  }
  @media (max-width: 650px) {
    padding: 1rem 2rem;
    flex-direction: column;
  }
}

.product-image {
  flex: 1;
  display: flex;
  margin-right: 20px;

  @media (max-width: 650px) {
    width: 100%;
    margin: 0;
    margin-bottom: 1.5rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    max-height: 26rem;
  }
}

.product-details {
  flex: 2;

  @media (max-width: 650px) {
    width: 100%;
  }

  .product-details-table {
    width: 100%;
    margin-bottom: 1rem;

    th {
      color: #333;
      text-align: left;
    }

    tr{
      vertical-align: top;
    }

    .attribute-cell {
      width: 30%;
      font-weight: bold;
      padding: 10px;
    }

    .value-cell {
      width: 70%;
      padding: 10px;
    }

    .even-row {
      background-color: #fff;
    }

    .odd-row {
      background-color: #f8f7f7;
    }
  }
}

//BM LIST Layout
.bm-list-wrapper {
  padding: 10rem 5rem 0rem;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;

  @media (min-width: 1521px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  @media print {
    margin-top: -8rem;
    margin-left: -2rem;
    margin-bottom: 50rem;
  }

  .item-container-wrapper {
    width: 100%;

    .item-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem;
      width: 100%;
      padding: 0rem;
      margin-bottom: 10rem;

      .item-card {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        width: 100%;
      }

      .item-image-container-wrapper {
        width: 50%;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;

        .item-image-container {
          display: flex;
          flex-direction: row;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          background-color: white;
          border-radius: 0.25rem;
          width: 100%;
          cursor: pointer;

          .item-image {
            max-width: 90%;
            width: auto;
            height: 10rem;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            align-items: center;
            padding: 10% 5%;
          }
        }

        .item-index-container {
          margin-top: 0.5rem;
          display: none;
          @media print {
            display: block;
          }  
        }
      }
    }

    .item-details {
      margin-left: 0.75rem;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: start;
      justify-content: flex-start;
      align-items: flex-start;

      .item-name {
        margin-top: 0.2rem;
        width: 100%;
        min-height: 3rem;
        text-align: left;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
      }

      .price .moq {
        margin: 0.2rem 0px;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: start;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;
      }

      .moq {
        @media print {
          display: none;
        }
      }

      .add-to-list-wrapper {
        margin-top: 1rem;

        @media print {
          display: none;
        }
      }

      .print-quantity{
        display: none;
        @media print {
          display: block;
        }
      }
    }
  }

  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .no-data-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 100%;
    height: 100%; 
  }
}

.item-container img {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;
  height: 15rem;
  border-radius: 0rem;
}


//Email List 
.email-list-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #f7fafc;
  position: relative;

  @media print {
    .item-card {
      page-break-inside: avoid;
      break-inside: avoid;
    }

    button {
      display: none;
    }
  }

  .MuiButton-contained {
    color: white;
    background-color: #147350 ; 
    border-radius: 2rem;

    &:hover {
      color: white ;
      background-color: #147350 ; 
    }

    @media (max-width: 770px) {
      font-size: 0.7rem;
    }
  }

  .MuiSvgIcon-root {
    @media (max-width: 770px) {
      width: 1.1rem;
    }
  }

  @media (max-width: 770px) {
    width: 8rem;
    padding: 0rem;
  }
}
