.badge {
    background-color: #ff6d00; 
    color: #ffffff; 
    padding: 2px 4px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    box-shadow: 0 4px 6px rgba(22, 20, 20, 0.1);
    border-radius: 5px;
  
    &.badge-new-updates {
      border-radius: 5px 0 0 5px; 
      font-size: 12px;
      padding: 4px 8px;
    }
  }
  
  