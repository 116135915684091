.contactUsBtn{
    border: none;
    color: inherit;
    background-color: inherit;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
    font-weight: inherit;
    font-size: inherit;
  }
.contact-block{
    display: flex;
    justify-content: center;
    align-content: space-between;
}
.contactModal{
    min-width: 20rem;
    min-height: 25rem;
}
.contactUs-container{
    margin-top: 11rem;
    position: absolute; 
    overflow: auto;
    max-width: 35rem;
    min-width: 15rem;
    width: auto;
    max-height: 35rem;
    min-height: 15rem;
    height: auto;
    outline: none;
    border-radius: 1.2rem ;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    padding-bottom: 0;
}
.contact-modal-body{
    padding: 3rem ;
    padding-top: 1rem;
    padding-bottom: 0rem;
}
.contactBtnContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
}
.contactModalCloseBtn {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0;
}
.contactModalCloseBtn button {
    border: none;
    border-radius: 2.1875rem;
    font-size: 1rem;
    color: white;
    background-color: #d8d5d5;
    cursor: pointer;
    position: fixed;
  }
.submitBtn-disabled{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: #656565;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 30px;
}
.submitBtn{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: #147350;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 30px;
}
.contactFormInput{
    display: flex;
    flex-direction: column;
    width: 280px;
}

.contactFormInput-Res{
    display: flex;
    flex-direction: column;
}

.formInput{
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
    border: 1px solid #c8c8c9;
    font-size: 1rem;
}

.formInputLabel{
    font-size: larger;
    color: #147350;
    text-align: center;
    font-weight: 800;
    font-size: 22px;
    margin-top: 0.5rem;
}

.contact-response-green {
    padding-top: 5px;
    text-align: center;
    font-size: 1.3rem;
    color: #147350;
    display: flex;
    justify-content: center;
}

.contact-response-black {
    padding-top: 2rem;
    text-align: center;
    font-size: 1.3rem;
    color: #000000;
    display: flex;
    justify-content: center;
}

.contact-response-red {
    padding-top: 5px;
    text-align: center;
    font-size: 1.3rem;
    color: #c30707;
    display: flex;
    justify-content: center;
}

.formInputError{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
    margin-top: -0.7rem;
    margin-bottom: 0.7rem
}

.MuiInputBase-input-MuiOutlinedInput-input{
    padding: 5px;
    margin: 10px 0px;
    height: 0.5rem !important;
    border-radius: 5px;
    border: 1px solid gray;
}
.MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 1px;
}
.MuiAutocomplete-input{ 
    padding: 4px;
    font-family: 'Roboto Condensed', sans-serif !important;
}
.MuiOutlinedInput-root {
    padding: 7px !important;
}
.MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0px !important;
}

input:invalid[focused="true"]{
    border: 1px solid red;
}

input:invalid[focused="true"] ~ span{
    display: block;
}
.ReactModal__Overlay{
    background-color: rgba(87, 87, 85, 80%)!important;
}
.confirmBtn{
    width: 48%;
  }
  @media (max-width: 800px) {
    .submitBtn{
        width: 100%;
        height: 50px;
        padding: 10px;
        font-weight: bold;
        font-size: 0.2rem;
    }
    .submitBtn-disabled{
        width: 100%;
        height: 50px;
        padding: 10px;
        font-weight: bold;
        font-size: 0.2rem;
    }
}
  @media (max-width: 430px) {
    .contact-container{ 
        width: 5rem;
        height: auto;
        padding: 2rem 3rem;
    }
    .contactModalCloseBtn {
        margin-top: -2.8rem;
        margin-right: -1.8rem;
    }
    .formInputLabel h1{
        font-size: 2rem;
        margin: 2rem auto;
    }
    .contactFormInput{
        width: 235px;
    }
}
@media (max-width: 325px) {
    .contact-container{ 
        width: 4rem;
        height: auto;
        padding: 1rem 2rem;
    }
    .contactModalCloseBtn {
        margin-top: -2rem;
        margin-right: -1rem;
    }
}