@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&family=Roboto+Condensed:wght@400;700&family=Roboto+Mono:wght@400;500;600;700&display=swap');
body{
  font-family: 'Roboto Condensed', sans-serif;
}
.leadership {
  position: relative;
    top: 7.5rem;
    overflow: hidden;
    background-color: #f0f0f0;
    margin-bottom: 7rem;
}

nav{
    display: flex;
    flex-direction: row;
    font-size: 22px;
    text-align: center;
    justify-content: center ;
    padding: 13px;
}
.text-link{
  text-align: center;
  text-decoration: none;
  line-height: 32px;
  padding: 20px;
  color:grey;
  font-family: 'Right Grotesk Dark';
  font-weight: bold;
  transition: all 150ms;
}
.text-link:active{
    text-align: center;
    text-decoration: none;
    line-height: 32px;
    padding: 20px;
    color:grey;
}
.text-link:active:hover{
    cursor: pointer;
    color:#147350;
    text-decoration: none;
}
.text-link:active,
.text-link.active{
    color:#147350 ;
    border-bottom: 3px solid #147350;
}
.leadership-list{
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 3.5rem 5.5rem 0 0;
}
.first-row{
  margin-bottom: 6rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 6rem;
}

.custom-container {
  max-width: 1550px !important; 
}

@media (max-width: 1200px) {
  .custom-container {
    max-width: 900px !important; 
  }
}

@media (max-width: 768px) {
  .custom-container {
    max-width: 600px !important; 
  }
}

@media (max-width: 480px) {
  .custom-container {
    max-width: 290px !important; 
  }
}

.leadership-list-2 {
  display: grid;
  grid-template-columns: repeat(6, 1fr); 
  gap: 1rem; 
  justify-content: center;
}

.leadership-list-2 .leadership-detail-image > img {
  width: 100%;
  height: auto;
  aspect-ratio: 3/4;
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .leadership-list-2 {
    padding-left:4.5rem; 
  }
}

@media (max-width: 1200px) {
  .leadership-list-2 {
    grid-template-columns: repeat(3, 1fr); 
    gap:3rem;
  }
}

@media (max-width: 768px) {
  .leadership-list-2 {
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media (max-width: 480px) {
  .leadership-list-2 {
    grid-template-columns: 1fr; 
  }
}

.btn-modal-close{
  height: 2rem;
  width: 1.5rem;
  margin: 0.2rem 0.5rem;
  color: #dfdcdc;
  cursor: pointer;
}
.openModalBtn{
    color: #147350;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;
    text-decoration: none;
    border: none; 
    font-size: 1rem;
    font-weight: 600;
    transition: all 150ms;
}
.openModalBtn:not(:disabled) {
    cursor: pointer;
    padding:0;
    display: flex;
    justify-content: flex-start;
}
.leadership-item-container{
  width: 11rem;
  margin :0 1rem;
  display: flex;
  flex-direction: row;
}

.modal-content{
  position: absolute;
  max-height: 58vh;
  height: auto;
  inset: 17.5rem 6.25rem 5rem 6.25rem;
  outline: none;
  border-radius: 0.3125rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  flex-direction: column;
  overflow: hidden;
}
.titleBtnContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
}
.titleCloseBtn {
  display: flex;
  justify-content: center;
  transform-origin: 0px 0px;
  width: 65px;
  position: fixed;
  margin-top: 2rem;
}
.titleCloseBtn button {
  border: none;
  border-radius: 2.1875rem;
  font-size: 1rem;
  color: white;
  background-color: #d8d5d5;
  margin-top: 1rem;
  margin-left: 1.5rem;
  cursor: pointer;
  position: fixed;
  }
  .leadership-container{
    padding: 1rem 2rem;
    overflow: hidden;
  }
  .container{
    width:100%;
    display: flex;
    overflow: hidden;
  }
  .first{
    padding-top: 3.75rem;;
  }
  .leadership-detail-image > img{
    border-radius: 0.5rem;
    width: 16.5rem;
    height: 22rem;
    box-shadow: rgb(0 0 0 / 30%) 0rem 0.3125rem 0.625rem;
  }
  .leadership-detail-image-firstrow > img{
    border-radius: 0.5rem;
    width: 21.1875rem;
    height: auto;
    box-shadow: rgb(0 0 0 / 30%) 0rem 0.3125rem 0.625rem;
  }
  .leadership-detail-image-model{
    padding: 0rem 1rem;
  }
  .leadership-detail-image-model > img{
    width: 21.1875rem;
    height: 28.25rem;
    border-radius: 0.5rem;
    box-shadow: rgb(0 0 0 / 30%) 0rem 0.3125rem 0.625rem;
  }

  .leadership-detail-bio{
    padding: 0 20px 20px 20px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .leadership-detail-bio p{
    font-size: 20px;
  }
  .leader_name{
    font-weight: 900;
    font-size: 34px;
    margin: 0;
    display: inline;
    line-height: 40.81px;
    font-family: 'Right Grotesk';
  }
  .leader_title{
    padding: 3px 10px 0 0;
    margin: 0;
    min-height: 3.75rem;
    line-height: 28.8px;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Right Grotesk';
  }
  .leadership-detail-resources{
    display:flex;
    justify-content: flex-start;

  }
  .social-link{
    color:#0077B5;
    padding:5px;

  }
  .social-link :hover{
    color: #369dd5;
  }
  .leadership-detail-downloads{
    padding-left: 20px;
  }
  
.download-link{
    text-decoration: none;
    color:#147350;
    padding-left: 0;
  }

.download-link,.download-link:visited,.download-link:hover,.download-link:active {
  text-decoration: none;
  color: #147350;
}
  .bsDownload{
    padding-right: 15px;
    padding-left: 5px;
  }

  .leadership2ndList{
    margin-top: 10rem;
  }

  .position{
    margin: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    gap: 0.625rem;
  }
  .singles{
    min-height: 25px;
  }
  .leader_detail_btn{
    display: flex;
    justify-content: center;
    width: 70%;
  }
  .descriptionContainer{
    width: 100%;
    height: auto;
    overflow-y: scroll;
    padding-right: 1.5rem;
  }
  .experienceWrapper{
    display: inline-block;
    margin-bottom: 1rem;
  }
  .leadersDescription{
    margin-bottom: 1rem;
  }
  
  @media(max-width:921px){
    .last-row{
      margin-bottom: 3rem;
    }
    .first-row{
      gap: 6rem;
    }
  }
  @media(min-width:750px){
    .descriptionContainer{
      max-height: 33vh;
    }
  }
  @media(max-width:750px){
    .container{
    display:flex;
    flex-direction:column;
  }
  .social-link{
    display: flex;
    flex-direction: column;
  }
  .download-link
  {
    display: flex-start;
    padding: 10px 0 10px 0;
    flex-direction: column;
  }

  .leaderImage{
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .modal-body{
    background-repeat:repeat-x ;
  }
  .leader_name{
   font-size: 26px;
  }
  .leadership-detail-bio p{
     font-size: 16px;
  }
  .container{
    overflow-y: scroll;
    max-height: 70vh;
    height: auto;
    width: 100%;     
  }
  .descriptionContainer{
    overflow-y: hidden;
  }
  .leadership-detail-bio{
    overflow: visible;
    background-color: white;
    margin-top: 1rem;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  .first-profile{
    margin-bottom: 3rem;
  }
  }
 
  @media(max-width:450px){
  
    .first-row{
      gap: 4rem;
    }
    .leaderImage{
      position: relative;
      text-align: left;
      width: 150px;
      height:160px;
      padding: 0;
      margin-bottom: -1.8rem;
      left: -50px;
      top: -30px;
    }
    .leadership-detail-image{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .modal-body{
      background-repeat:repeat-x;
      background-size: 110 110px;
      min-width: 90%;
    }
    .leader_name{
      font-size: 23px;
    }
    .leadership-detail-bio{
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-top: 1.2rem;
    }
    .leadership-detail-bio p{
      font-size: 14px;
      align-items: center;
      
      justify-content: flex-start;
    }
    .download-link{
      font-size: 8px;
    }
    .position{
      padding-left: 2rem;
      padding-top: 0.5rem;
    } 
  }
  @media(max-width:350px){
    .leadership-detail-image > img{
      width: 9.5rem;
      height: auto;
    }
    .leadership-detail-image{
      padding: 0.2rem
    }
    .position{
      padding-left: 0rem;
      padding-top: 0.5rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    } 
    .leader_name{
      padding: 0;
    }
    }
  @media(max-width:321px){
    .titleCloseBtn button{
      position: relative;
      margin-left: 1.8rem;
  }
  nav {
    font-size: 20px;
}
.leadership-detail-image > img{
  width: 8.5rem;
}
.leadership-detail-image{
  padding: 2rem
}
}