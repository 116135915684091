.partnership-page-wrapper {
    padding-top: 6rem;
    width: 100%;

    .partnership-layout {
        padding-left: 12rem;
        padding-right: 12rem;
        scroll-margin-top: 10rem;

        @media (max-width: 1521px) {
            padding-left: 10rem;
            padding-right: 10rem;
        }

        @media (max-width: 1050px) { //windows resolution
            padding-left: 5rem;
            padding-right: 5rem;
        } 
        
        @media (max-width: 500px) {
            padding-left: 1rem;
            padding-right: 1rem;
        } 
    }

    .contact-us-container {
        width: 14rem;
        height: 4rem;
        background-color: #147350;
        text-align: center;
        align-content: center;
        border-radius: 0.35rem;
        cursor: pointer;
        color: white;


        &:hover {
            background-color: #0f5a3d;
        }

        @media (max-width: 800px) {
            width: 12rem;
            height: 3rem;
        }

        @media (max-width: 500px) {
            width: 8rem;
            height: 2.5rem;
        }

        h5 {
            @media (max-width: 500px) {
                font-size: 1rem;
            }
        }

    }

    .heading-img-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 1rem;

        img {
            width: 3rem;
            height: auto;
            color: white;
        }
    }

    .wallpaper-img-container {
        width: 100%;
        position: relative;
        img {
            width: 100%;
            height: auto;
            display: block;
        }

        .overlay-text {
            width: 80%;
            color: white;
            font-weight: 900;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 1.5rem;
            position: absolute;
            bottom: 5%;
            left: 5%;
            margin-top: 0px;
            @media (max-width: 980px) {
                .overlay-text h1 {
                    font-size: 0.1vw;
                }
            }
            @media (max-width: 800px) {
                width: 90%;
                bottom: 1%;
                #partnership-info-text {
                    font-size: 2.5vw;
                }
            }

            @media (max-width: 500px) {
                row-gap: 0.5rem;
                #partnership-info-text {
                    font-size: 3vw;
                }
            }

            @media (max-width: 350px) {
                
                #partnership-info-text {
                    font-size: 12px;
                }
            }

            h1 {
                font-size: 5rem;
                line-height: 5rem;
                
                @media (max-width: 800px) {
                    font-size: 4rem;
                    line-height: 4rem;
                }

                @media (max-width: 500px) {
                    font-size: 2.75rem;
                    line-height: 2.75rem;
                }
            }

            h2 {
                font-size: 2rem;
                line-height: 2rem;
                
                @media (max-width: 800px) {
                    font-size: 1.25rem;
                    line-height: 1.25rem;
                }

                @media (max-width: 500px) {
                    font-size: 1rem;
                    line-height: 1rem;
                }
            }

            p {
                font-size: 1.5rem;
            }
        }
    }

    .section-heading {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .content-img-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .content-block-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 800px) {
            flex-direction: column;
            row-gap: 2rem;
        }

        .content-block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 45%;

            @media (max-width: 800px) {
                width: 90%;
            }

            img {
                width: 100%;
            }
        }
    }

    .customer-focus-list {
        list-style: none;
        padding: 0;

        li {
            position: relative;
            margin-bottom: 1rem;
            font-size: 1rem;
            line-height: 1.5;
            padding-left: 2rem;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0.2rem;
                width: 1.25rem;
                height: 1.25rem;
                background-image: url('../../../assets/images/StarBorderFilled.png');
                background-size: cover;
                background-repeat: no-repeat;
                color: #147350;
            }

            strong {
                font-weight: bold;
                margin-right: 0.2rem;
            }
        }
    }

    .page-section-header {
        background-color: #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .page-section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 16rem;
        margin: 3rem;

        .content-section {
            min-height: 16rem;
            margin: 3rem;
        }
    }

    .section-content-why {
        padding-top: 1.5rem;
        padding-bottom: 3rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url('../../../assets/images/picture2.png');
        background-repeat: no-repeat;
        background-size: auto 40%;
        background-position: right center;

        @media (max-width: 1050px) {
            background-size: auto 25%;
        }

        @media (max-width: 800px) {
            background-size: auto 20%;
            padding-bottom: 5rem;
        }

        .content-block {
            row-gap: 1rem;

            p {
                font-family: 'Right Grotesk';
                font-size: 1.5rem;
            }
        }
    }

    .section-content-how {
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        background-color: #f0f0f0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 800px) {
            padding-bottom: 4rem;
        }

        h3, h4{
            text-align: center;
        }

        .content-block-wrapper {
            .content-block {
                row-gap: 1rem;
            }
        }

        .section-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 2rem;
            row-gap: 2rem;

            h2 {
                padding-top: 3rem;
                padding-bottom: 1.25rem;
                font-family: Right Grotesk;
            }

            .card-content {
                background-color: white;
                border-radius: 0.25rem;
                padding: 1.25rem;
                height: 100%;
                display: flex;
                row-gap: 5%;
                flex-direction: column;

                h3 {
                    font-family: Right Grotesk;

                }
            }

            .content-block {
                height: 22rem;
                width: 48%;

                @media (max-width: 800px) {
                    width: 70%;
                }
            }
        }
    }

    .section-content-qualifications {
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #EC0F2A;
        box-sizing: border-box;
        row-gap: 1rem;

        .section-heading,
        .content-block {
            color: white;
        }

        .content-block {
            row-gap: 1rem;
            height: 100%;
            width: auto;

            @media (max-width: 800px) {
                align-items: center;
            }
        }

        .content-block-wrapper{
            height: 100%;
            column-gap: 7rem;
        }

    }

    .section-content-countries {
        padding-top: 1.5rem;
        padding-bottom: 3rem;
        background-color: #f0f0f0;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 3rem;

        .content-block-wrapper {
            justify-content: center;

            .content-block {
                width: 90%;
            }
        }
    }

    .section-content-contactus {
        height: 10rem;
        row-gap: 8%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;

        h6 {
            text-align: center;
        }
    }
}