.card {
    height: 45rem;
    background-color: white;
    color: black;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    row-gap: 0.5rem;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (max-width: 800px) {
        width: 100%;
    }

    @media (max-width: 500px) {
        width: 85%;
    }

    img {
        width: auto;
        height: 60%;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    .card-details {
        height: 38%;
        
        .card-heading-container {
            height: 20%;
            font-size: 0.75rem;

            @media (min-width: 1521px) {
                height: 19%;
            }
        }
    
        .card-subheading-container {
            height: 40%;

            @media (min-width: 1521px) {
                height: 25%;
                line-height: 1.6rem;
            }

            @media (min-width: 1921px) {
                height: 30%;
            }
    
            @media (max-width: 1521px) {
                height: 40%;
                font-size: 0.65rem;
                line-height: 1.75rem;
            }
        }
    
        .card-detail-container {
            height: 7rem;
    
            @media (max-width: 1521px) {
                height: 5rem;
                font-size: 0.75rem;
            }
        }
    }

}

.card#vietnam {
    img {
        object-fit: contain;
    }
}