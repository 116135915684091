.container1-h1{
  position: relative;
  top: 7.5rem;
  height: 100vh;
  text-align: center;
}

.container1-h1-center{
  text-align: center;

}

.folder-container{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10rem;
  padding: 1rem;
}
.table-container{
  width: 60%;
  height: 450px;
  overflow: scroll;
}

.uploadFileButton{
  border-radius: 5rem;
  padding: 0.5rem 1.6rem;
  border: none;
  text-decoration: none;
  font-size: medium;
  background-color: #147350;
  color: white;
  cursor: pointer;
}

.uploadFileButton:hover {
  filter: brightness(1.2);
}

/* edit */

Table a {
    /* Font & Text */
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  text-decoration: none;

    /* Color & Background */
    color: rgba(10, 58, 42, 0.9);

    /* Miscellaneous */
    overflow: visible;
    cursor: pointer;
    visibility: visible;

    /* Effects */
    transform: none;
    transition: all 0s ease 0s;
}
.locationContainer{
  width: 60%; /* same as table width */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadButtonContainer{
  margin-bottom: -1rem;
  margin-right: 0.3rem;
  margin: 1rem;
  text-align: center;
  padding-top: 2rem;
}
.uploadContainer{
  margin-bottom: -1rem;
  margin-right: 0.3rem;
}
.contact-block{
  display: flex;
  justify-content: center;
  align-content: space-between;
}
.contactModal{
  min-width: 20rem;
  min-height: 25rem;
}
.contact-container{
  margin-top: 14rem;
  position: absolute; 
  overflow: auto;
  max-width: 35rem;
  min-width: 15rem;
  width: auto;
  max-height: 30rem;
  min-height: 15rem;
  height: auto;
  outline: none;
  border-radius: 1.2rem ;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 3rem 5rem;
}
.btnContainer{
display: flex;
justify-content: flex-end;
align-items: center;
z-index: 1;
}
.contactModalCloseBtn {
display: flex;
justify-content: center;
transform-origin: 0px 0px;
width: 0px;
margin-top: -4.4rem;
margin-right: -3rem;
}
.contactModalCloseBtn button {
border: none;
border-radius: 2.1875rem;
font-size: 1.4rem;
color: white;
background-color: #d8d5d5;
cursor: pointer;
position: fixed;
}
.processing-block{
z-index: 10;
background-color: black;
}

.fileUpload-formInputLabel {
font-size: larger;
color: #147350;
text-align: center;
display: flex;
justify-content: space-between;
}
.confirmationBtnContainer{
display: flex;
justify-content: space-between;
margin: 0 0 -1rem;
}
.confirmationBtnContainer-upload{
display: flex;
flex-direction: column;
justify-content: space-between;
margin: 0 0 -1rem;
}
.confirm-dialog-header{
color: black;
line-height: 1.3;
margin: 0 0 1.5rem;
}
.confirm-dialog-message{
color: #959292;
font-size: 0.83em;
line-height: 1.8rem;
margin: 0 0 -0.5rem;
}
.modal-close-btn {
top: 2px;
position: relative;
}
.confirmBtn{
width: 48%;
}
.processing-modal{
padding-top: 4.1rem;
}
.processing-modal-black{
padding-top: 4.1rem;
margin-left: 1rem;
margin-right: 1rem;
}
.delete-processing{
margin-top: 0rem;
}

@media (max-width: 546px) {
.uploadFileButton{
border-radius: 5rem;
padding: 0.4rem 1rem;
border: none;
font-size: small;
text-decoration: none;
background-color: #147350;
color: white;
cursor: pointer;
}
.table-container{
width: 80%;
margin-left: -2rem;
}
.locationContainer{
width: 80%; /* same as table width */
margin-left: -2rem;
}
.folder-container h1{
font-size: 3rem;
margin-left: -2rem;
}
}

@media (max-width: 450px) {
.uploadFileButton{
border-radius: 5rem;
padding: 0.4rem 0.7rem;
margin: 1rem -0.1rem 1.5rem 1rem;
border: none;
font-size: smaller;
text-decoration: none;
background-color: #147350;
color: white;
cursor: pointer;
}
.contact-container{
min-width: 7rem;
min-height: 7rem;
padding: 1.5rem 2.5rem;
}
}
