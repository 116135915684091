$listHeadingColor: #000000;
$primaryColorGreen: #147350;

body {
    background-color: #F1F1F1;
}

.careersWrapper {
    margin-top: 10rem;
    padding: 0 5rem;
    padding-bottom: 0rem;
    @media (max-width: 900px) {
        padding: 0 3rem;
    }
    @media (max-width: 500px) {
        padding: 0 2rem;
    }
    h1, h2, h3 {
        font-family: 'Right Grotesk Dark';
        font-size: 4rem;
    }
    .para {
        font-family: 'Right Grotesk Dark';
        font-size: 24px;
        margin: 1rem 0px;
        padding: 0 2rem;
        font-weight: lighter;
    }
    .addJobBtn {
        font-family: 'Roboto Condensed', sans-serif;
        border-radius: 8px;
        padding: 0.7rem 1.6rem;
        border: none;
        font-weight: 900;
        text-decoration: none;
        font-size: medium;
        background-color: $primaryColorGreen;
        color: white;
        cursor: pointer;
        align-items: center;
        margin: 20px;
        @media (max-width: 900px) {
            margin: 10px;
        }
    }

    .applyBtn {
        background-color: $primaryColorGreen;
        color: white;
        border-radius: 5px;
        font-size: 1rem;
        cursor: pointer;
        margin: 1rem auto;
    }

    .headingBtnWrapper {
        margin: 1rem auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .newJobBtn {
            max-width: 150px;
            align-self: center;
        }
    }

    .formListWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .listContainer {
            width: 100%;

            .jobLi {
                width: 100%;
                background-color: #ffffff;
                border-radius: 8px;
                padding: 0.5rem 0rem;
                margin: 1rem 0rem;
                padding-left: 1rem;
                padding-right: 0px;
                .jobSubHeadingContainer {
                    display: flex;
                    flex-direction: row;
                    font-weight: lighter;
                }
                .jobSubHeadingContainer p {
                    padding: 0;
                    margin: 0;
                    margin-right: 2.5rem;
                    font-family: 'Roboto';
                    font-size: 1rem;
                    font-weight: 400;
                }
                .jobDescWrapper p {
                    padding: 0;
                    margin: 0;
                    font-family: 'Roboto';
                    font-size: 1rem;
                    font-weight: 400;
                }
                .jobDescWrapper {
                    width: 100%;
                    .readMoreSpan {
                        display: inline-block;
                        color: #147350;
                        cursor: pointer;
                        margin: 1rem auto;
                        margin-bottom: 0rem;
                    }

                    .deleteEditContainer {
                        display: inline-block;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        right: 3rem;
                        bottom: 0;
                        .iconBtn {
                            padding: 0;
                            background-color: #147350;
                            color: #147350;
                            cursor: pointer;
                        }

                        .deleteEditIcon {
                            cursor: pointer;
                            margin: 0 0.25rem;
                            fill: $primaryColorGreen;
                            height: 1.3rem;
                            padding: 0.2rem 0;
                        }
                    }

                    .text-wrapper {
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        word-break: break-word;
                        text-overflow: ellipsis;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                    }
                }
            }
        }
    }

    .modalWrapper {
        position: fixed;
        top: 0rem;
        left: 0rem;
        right: 0;
        bottom: 0;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;

        &.showModal {
            display: flex;
        }
    }
    .applyBtn:hover,
    .newJobBtn:hover,
    .addJobBtn:hover {
        background-color: $primaryColorGreen;
    }
    .modalBody {
        max-height: 90vh;
        min-width: 60vw;
        width:100%;
        overflow-y: scroll;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        background-color: white;
        border-radius: 0.5rem;
        padding: 0px 1rem;
        padding-top: 2px;
        width: auto;
        height: auto;

        .formWrapper {
            display: flex;
            justify-content: center;

            .formContainer {
                width: 100%;

                .twoFieldRow {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                @media (max-width: 800px) {
                    .twoFieldRow{
                        flex-direction: column;
                    }
                }

                .submitBtn-disabled {
                    width: 15%;
                    height: 2.5rem;
                    padding: 10px;
                    border: none;
                    background-color: #656565;
                    color: white;
                    font-weight: bold;
                    font-size: 1rem;
                    margin-top: 15px;
                    margin-bottom: 30px;
                    float: right;
                    border-radius: 8px;
                }

                .submitBtn {
                    width: 15%;
                    height: 2.5rem;
                    padding: 10px;
                    border: none;
                    background-color: $primaryColorGreen;
                    color: white;
                    font-weight: bold;
                    font-size: 1rem;
                    cursor: pointer;
                    margin-top: 15px;
                    margin-bottom: 30px;
                    float: right;
                    border-radius: 8px;
                }
                .cancelBtn {
                    width: 15%;
                    height: 2.5rem;
                    padding: 10px;
                    border-color: $primaryColorGreen;
                    font-weight: bold;
                    font-size: 1rem;
                    cursor: pointer;
                    margin-top: 15px;
                    margin-bottom: 30px;
                    border-radius: 8px;
                }

                .text-button {
                    background: none;
                    border: none;
                    padding: 0;
                    margin: 0;
                    font-size: inherit;
                    color: black;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-top: 15px;
                    margin-bottom: 30px;
                    margin-right: 1rem;
                }

                .btn-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }
        }

        .twoFieldRow>.inputLabelWrapper {
            width: 100%;
        }
        
        .inputLabelWrapper {
            display: flex;
            flex-direction: column;
            margin: 1rem;
            width: 100%;
            .astrixWrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                width: 99%;
                margin-top: 0.5rem;

                .inputLabel {
                    font-size: 1rem;
                }

                .astrix {
                    color: red;
                }
            }

            .descriptionAstrixWrapper {
                margin-bottom: 0.8rem;
                position: relative;
            }

            .astrixContainer {
                position: absolute;
                right: 0;
                top: 50%;
            }

            .careerFormInput {
                display: flex;
                flex-direction: column;
                width: 100%;

                .formInput {
                    padding: 10px;
                    margin: 10px 0px;
                    border-radius: 5px;
                    border: 1px solid #c8c8c9;
                    font-size: 1rem;
                }

                .formInputError {
                    min-height: 2rem;
                    font-size: 12px;
                    padding: 3px;
                    color: red;
                    display: none;
                    margin-top: -0.7rem;
                    margin-bottom: 0.7rem
                }

                input:invalid[focused="true"] {
                    border: 1px solid red;
                }

                input:invalid[focused="true"]~span {
                    display: block;
                }

                .ck-content.ck-editor__editable {
                    max-height: 15rem;
                }
            }

            .url-input {
                min-height: 7rem;
            }
        }

        .confirmationContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 1rem 0rem;

            .confirmationText {
                text-align: center;
                font-weight: 800;
                font-size: 22px;
                margin-top: 0.5rem;
                margin-bottom: 0.7rem;

                .confirmHeader {
                    color: black;
                    line-height: 1.3;
                    font-family: 'Right Grotesk Dark';
                    margin: 0 0 0.7rem
                }

                .confirmMessage {
                    color: #959292;
                    font-size: 0.83em;
                    line-height: 1.8rem;
                    margin: 0 0 -0.5rem
                }
            }

            .confirmationBtnContainer {
                display: flex;
                justify-content: center;
                margin: 0 0 -1rem;

                .confirmBtn {
                    width: 25%;
                    height: 3rem;
                    padding: 10px;
                    border: none;
                    background-color: #147350;
                    color: white;
                    font-weight: bold;
                    font-size: 1rem;
                    cursor: pointer;
                    margin-top: 15px;
                    margin-bottom: 30px;
                    float: right;
                    border-radius: 8px;
                }

                .cancel {
                    background-color: rgb(183, 180, 180);
                    margin-right: 1rem
                }
            }
        }


    }

    .iconWrapper {
        position: relative;
        width: 100%;
        padding-right: 1rem
    }

}