.header-wrapper {
    width: 100%;
    height: 6rem;
    max-height: 9.5rem;
    z-index: 2;
    position: fixed;
    background-color: white;

    .top-header {
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo-link-wrapper {
            display: flex;
            align-items: center;

            .logo-container {
                width: 6rem;
                height: 6rem;
                margin-right: 2rem;

                img {
                    width: inherit;
                    height: auto;
                }
                @media (max-width: 1110px) {
                    margin-right: 0;
                }
            }
        }

        .login-icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-family: Right Grotesk;
            font-size: 1.5rem;
            font-weight: 800;
            line-height: 1.5rem;
            width: max-content;
        }

        .login-mobnav-wrapper {
            display: flex;
            align-items: center;
            column-gap: 3%;
        }
    }

    .bottom-header {
        padding: 0 1rem;
        height: 3rem;
        background-color: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid #0000001F;
    }
    .badge-Padding{
        padding: 1rem 1rem 0;
        height: 4rem;
        background-color: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid #0000001F;
        .showBadge{
            position: relative;
            .Badge {
                position: absolute;
                top: -1.45rem; 
                right: 0.5rem;
            }
        }
    }

    .header-link {
        cursor: pointer;
        padding: 0 1.5rem 0 0 ;
        color: #000000;
        text-align: center;
        text-decoration: none;
        font-family: Right Grotesk;
        font-size: 1.5rem;
        font-weight: 800;
        line-height: 1.5rem;
        @media (max-width: 1110px) {
            padding: 0 0.5rem 0 0 ;
        }

        &:hover {
            color: #147350;
            text-decoration: none;
        }
      
        &.active {
          color: #147350;
        }
    }

    .loggedin-link{
        font-size: 1.25rem;
        font-weight: 400;
    }

    @media (max-width: 930px) {
        .hide-MV {
            display: none !important;
        }
    }

    @media print {
          display: none;
        
    }
}