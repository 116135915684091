.newsFeedBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 40rem;
    margin: 0 2rem 0 0;
}
.newsFeedBlockProfile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 40rem;
    margin: 0 2rem 0 1.8rem;
}
.newsFeedBlockItem{
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: white;
}
.span-heading{
    font-weight: 800;
}
@media (max-width: 1300px) {
    .newsFeedBlock{
        margin: 0;
    }
    .newsFeedBlockProfile{
        margin: 0;
    }
}
@media (max-width: 950px) {
    .newsFeedBlock{
        width: 36rem;
        margin-left: 1rem;
    }
    .newsFeedBlockProfile{
        width: 36rem;
        margin-left: 1rem;
    }
}
@media (max-width: 875px) {
    .newsFeedBlock{
        width: 30rem;
        margin-left: 3rem;
    }
    .newsFeedBlockProfile{
        width: 30rem;
        margin-left: 3rem;
    }
}
@media (max-width: 775px) {
    .newsFeedBlock{
        width: 27rem;
        margin-left: 4rem;
    }
    .newsFeedBlockProfile{
        width: 27rem;
        margin-left: 4rem;
    }
}
@media (max-width: 700px) {
    .newsFeedBlock{
        margin-left: 2rem;
    }
    .newsFeedBlockProfile{
        margin-left: 2rem;
    }
}
@media (max-width: 650px) {
    .newsFeedBlock{
        width: 23rem;
        margin-left: 4rem;
    }
    .newsFeedBlockProfile{
        width: 23rem;
        margin-left: 4rem;
    }
}
@media (max-width: 640px) {
    .newsFeedBlock{
        width: 23rem;
        margin-left: 1rem;
    }
    .newsFeedBlockProfile{
        width: 23rem;
        margin-left: 1rem;
    }
}
@media (max-width: 550px) {
    .newsFeedBlock{
        width: 23rem;
        margin-left: 0rem;
    }
    .newsFeedBlockProfile{
        width: 23rem;
        margin-left: 0rem;
    }
}