.common-grid-container {
    width: 100%;
    height: 305px;
    position: relative;

    .data-grid {
        min-height: 20rem; 
        height: 95%; 
        width: 100%;
    }

    .rows-count {
        font-size: 14px;
        color: #555;
    }

    .pagination-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
            span {
                margin-right: 0.5rem;
            }
        }

        .MuiPagination-root {
            margin-left: 1rem;
        }

        #rows-per-page {
            margin-right: 1rem;
            padding-right: 7px;
        }
    }

    .active-row {
        border: 2px solid green;
        color: green;
        background-color: #eaffea;
    }

}