@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&family=Roboto+Condensed:wght@400;700&family=Roboto+Mono:wght@400;500;600;700&display=swap');
body{
    line-height: 1.5;
    font-family:"Montserrat", sans-serif;
}
.slide-section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.brandstory {
    position: relative;
    top: 7.5rem;
    overflow: hidden;
    margin-bottom: 7rem;
    background-color: #f0f0f0;
}

.about-nav{
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    text-align: center;
    justify-content: center ;
    padding: 13px;
}
.text-link{
    text-align: center;
    text-decoration: none;
    line-height: 32px;
    padding: 20px;
    color:grey;
    font-family: 'Right Grotesk';
}
.text-link:hover{
    cursor: pointer;
    color:#147350;
    text-decoration: none;
}
.text-link:active,
.text-link.active{
    color:#147350 ;
    border-bottom: 3px solid #147350;
}
.page-header{
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 3rem;
}
.page-header h1{
    text-align: center;
    font-family: 'Right Grotesk'; 
    font-weight: bold; 
    font-size: 3.75rem;
    line-height: 4.500625rem;
}
.brandstory-success{
    
    background-image: url("../../assets/images/picture2.png");
    background-repeat: no-repeat;
    background-position: right 66%;
    background-size: auto 250px;
}
.brandstory-success h1{
    font-size: 36px;
}
.brandstory-flex{
    display: flex;
    flex-direction: row;
    padding: 20px 23px;;
    align-items: center;
}
.brandstory-img {
    margin: 0 4rem 0 1rem;
}
.brandstory-img img{
    border-radius: 15px;
    width: auto;
    height: 30rem;
    max-width: 100%;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 10px;
}
.brandstory-content{
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding: 5px 5rem 0 6rem;
    text-align: left;
    margin: auto;
    width: 50%;
}
.brandstory-content p{
    text-align: justify;
    text-justify: inter-character;
}
.brandstory-vision{
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    background-color: #007350;
    color: #f0f0f0;
}
.brandstory-vision p{
    font-size: 1.5rem;
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
    font-weight: 700;
    font-family: 'Right Grotesk Dark';
    line-height: 28.8px;
}
.brandstory-vision h1{
    font-size: 3rem;
    margin: 0;
    font-weight: 900;
    font-family: 'Right Grotesk';
    line-height: 3.60rem;
}
.brandstory-misson {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    background-color:white;
    color: black;
}
.brandstory-misson p{
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 700;
    font-family: 'Right Grotesk Dark';
    line-height: 28.8px;
}
.brandstory-misson h1{
    font-size: 3rem;
    margin: 0;
    font-weight: 900;
    font-family: 'Right Grotesk';
    line-height: 3.60rem;
}
.explore_our_story{
    padding:2rem 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color:#f0f0f0;
    color: black;
}
.explore_our_story h1{
    font-size: 3rem;
    margin: 0;
    font-weight: 900;
    font-family: 'Right Grotesk';
    line-height: 3.60rem;
}
.explore_our_story p{
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 700;
    font-family: 'Right Grotesk Dark';
    line-height: 28.8px;
}
.through_the_years{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5rem;
}
.responsive-image {
    width: 100%;
    max-width: 800px; 
    height: auto; 
    padding-bottom: 0rem;
  }
.brandstory-timeline{
    background-image: url("../../assets/images/logo1.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 320px auto;
    padding: 60px 80px 60px 80px;
}
.section{
    position: relative;
    clear: both;
    margin-top: 0;
    margin-bottom: 0;
    width:89.0%;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding: 10px 40px 10px 60px;
}
.brandstory-image{
    background-image: url("../../assets/images/picture3.jpeg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 3.125rem;
    height: 230px;
    margin: auto;
    width: 84%;
    padding:200px 0 200px 0;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 10px;
}
.brandstory-timeline-row{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
}
.brandstory-timeline-content{
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
}
.brandstory-timeline-content h2{
    font-size: 50px;
    margin: 1rem;
    display: inline;
    justify-content: center;
    align-items: center;
    color: white;
}
.brandstory-timeline-content p{
    margin: auto;
}
.about-btn{
    color: #147350;
    text-align: center;
    text-decoration: none;
    border-radius: 25px;
    border: 1px solid black;
    border-color:white !important;
    font-family:"Roboto Condensed", sans-serif;
    font-size: 1.5rem;
    padding: 15px;
    background-color: white;
    font-weight: bolder;
}
.about-btn:not(:disabled) {
    cursor: pointer;
}
.about-btn:hover{
    background-color: #147350;
    color:white;
}
.modal-body-container{
    margin-right: 5rem;
    padding-left: 5rem;
    padding-top: 3rem;
    padding-bottom: 1rem;
}
.modal-body-container> h1{
  font-size: 56px;
  padding-left: 4rem;
}
.modal-story-container{
  position: absolute; 
  overflow: auto;
  width: auto;
  inset: 142px 40px 80px 40px;
  outline: none;
  border-radius: 1.2rem ;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
}
.modalBtnContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
}
.modalCloseBtn {
    display: flex;
    justify-content: center;
    transform-origin: 0px 0px;
    width: 80px;
}
.modalCloseBtn button {
    font-size: 1.2rem;
    color: lightslategray;
    background-color: white;
    cursor: pointer;
    position: fixed;
  }
  .timelineCloseBtn{
    position: fixed;
    margin-top: 3rem;
  }
.corp_contact-us{
    background-color: #f0f0f0;
    padding:20px;
    clear:both;
}

.text-18{
   padding-top:0;
}
.faq {
    color: #147350;
    text-decoration: none;
}
.faq:hover{
    color: #008755;
    text-decoration: none;
}
.prefooter{
    background-color: #147350;
    height: 200px;
    
}
.link_container{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    padding-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}
.about-row{
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    height: 100%;
    text-align: center;
    align-items: center;
}
.store,.app{
    display: block;
    flex: 0 0 auto;
    width: 50%;
    margin:auto;
    align-items: center;
    display: inline;
}
.prefooter-a{
    color:white;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    font-size: 25px;
}
.prefooter-a:hover{
    cursor: pointer;
    text-decoration: underline;
}
.mobileIcon, .locationIcon{
    padding-top: 18px;
}
.mobileIcon, .locationIcon:hover{
    text-decoration: underline;
}
/* @media only screen and (min-width: 1170px){ */
 .vertical-timeline-element-date {
    font-size: 2rem !important;
    top: -1.1rem !important;
}
.vertical-timeline-element-title{
    font-size: 2rem;
}
.vertical-timeline-element-content p{
    font-size: 0.8rem;
    margin: 0 !important;
}
.vertical-timeline-content {
    padding: 0em !important;
}
.customBlock{
    height: 1rem;
}
.customBlock2{
    height: 10rem;
}
.vertical-timeline-element-date{
    color: black;
}
@media (min-width:2550px ) {
    .brandstory-content{
        width: 53%;
    }
}
@media (min-width:2840px ) {
    .brandstory-content{
        padding: 5px 2rem 0 1.2rem
    }
}


@media (max-width:1321px ){
    .brandstory-content{
        padding: 5px 4rem 0 5rem;
        width: 50%;
        font-size: 17px;
    }
    .brandstory-img img{
        margin: 0;
    }
}

@media(max-width:1266px){
    .brandstory-image{
     padding-top: 10px;
     padding-bottom: 20px;
     height: 30rem;
     width: 83%;
    }
    .brandstory-timeline-row{
     padding-top: 144px;
    }
     
  }
  @media(max-width:1170px){
    .vertical-timeline-element-date {
        color: white;
    }
}

@media(max-width:1100px) {
    .brandstory-image {
        width: 83%;
    }
}

@media (max-width:1010px ){
    .brandstory-flex{
        display: flex;
        flex-direction: column-reverse;
        padding: 60px 60px 25px 60px;
        align-items: center;
        justify-content: center;
    }
    .brandstory-success{
        background-repeat: no-repeat;
        background-position: right 0 top 28%;
        background-size: 70% auto;
    }
    .brandstory-content {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0;
        width: 83.33%;
        padding: 0;

    }
    .brandstory-img img{
       
        display: block;
        width: 550px;
        height: auto;
        margin: auto;
        margin-bottom: 2rem;
        border-radius: 33px;
        padding: 0;
    }
    .brandstory-image {
        width: 74%;
    }
    .container-footer {
        padding: 3em 8.1rem 0;
    }
} 
 @media (min-width: 1601px){
    .store{
        width: 30%;
        margin-right: 200px;
    }
    .app{
        width: 30%;
        margin-left: 200px;
    }
}

@media(max-width:931px){
    .brandstory-timeline{
        background-position: right bottom;
    }
 }

 @media(max-width:769px){
    .brandstory-content{
        padding-left: 2.2rem;
        padding-right: 2.2rem;
        width: 94%;
    }
    .brandstory-flex{
        padding: 3rem 5.5rem 1rem;
        margin: 3rem;
    }
    .brandstory-vision {
        padding: 0 6rem 1rem;
    }
    .brandstory-image {
        width: 74%;
        height: 21rem;
    }
    .brandstory-timeline-row {
        padding-top: 74px;
    }
    .section-content {
        width: 95%;
    }
    .section-content-item{
        width: 93%;
        margin: 0.3rem; 
    }
    .modal-body-container{
        padding-left: 2rem;
    }
    .container-footer {
        padding: 3em 6.5rem 0;
    }
}

@media(max-width:620px){
    .brandstory-timeline-content h2{
        font-size: 20px;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .brandstory-image{
        width:100%;    
        margin: auto;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .brandstory-timeline-content{
        padding: 0;
    }
    .section{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 60px 90px 40px 0;
        margin-right: 20px;
    }
 }

@media(max-width:597px){
    .page-header h1{
        font-size: 40px;
    }
    .brandstory-success h1{
        font-size: 23px;
        margin-bottom: 3rem;
    }
    .brandstory-content p{
        font-size: 14px;
    }
    .brandstory-vision-quotes h1{
        font-size: 23px;
        padding: 2rem 0 0 0;
        background-color: #008755;
    }
    .brandstory-vision-quotes p{
        padding:5px 20px 0 20px;
        font-size: 14px;
        background-color: #008755;
    }
    .brandstory{
        top: 6rem;
    }
    .brandstory-flex{
        padding: 0;
        margin: 1rem;
    }
}

@media(max-width:426px){
    .brandstory-content{
        padding-left: 2rem;
        padding-right: 2rem;
        width: 96%;
    }
    .brandstory-flex{
        padding-left: 2.3rem;
        padding-right: 2.3rem;
        margin: 10px;
    }
    .section-content-item{
        width: 75%;
        margin: 0.3rem; 
    }
    .brandstory-vision-quotes{
        padding:1rem
    }
    .modal-body-container{
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        margin-right: 3.5rem;
        margin-left: 0rem;
    }
    .container-footer {
        padding: 3rem 2.7rem 0;
    }
    .about-btn{
        font-size: 0.8rem;
    }
    .modal-body-container> h1 {
        font-size: 35px;
        padding-left: 4rem;
    }
    .brandstory-img {
        margin: 0 1rem 0 1rem;
    }
    .brandstory-img img {
        width: auto;
        height: 20rem;
        margin: -2rem auto 2rem;
        border-radius: 15px;
        box-shadow: rgb(0 0 0 / 15%) 0px 5px 10px
    }
    .about-btn {
        padding: 7px 11px;
    }
    .brandstory-image {
        width: 95%;
        height: 15rem;
        margin-top: -3rem;
    }
    .brandstory-success{
        background-position: right 0 top 18%;
    }
    .brandstory-vision {
        padding: 0 2rem 0rem;
    }
    .brandstory-timeline-row {
        padding-top: 1rem;
    }
    .contact-heading {
        font-size: 23px;
    }
    .contactCard {
        font-size: 19px;
    }
    .brandstory{
        top: 5rem;
    }
}
@media(max-width:361px){
    .brandstory-flex{
        margin: 6px;
    }
}
@media(max-width:321px){
    .brandstory-flex{
        padding-left: 2.3rem;
        padding-right: 2.3rem;
        width: 77%;
    }
    .section-content-item{
        width: 70%;
        margin: 0.3rem; 
    }
    .container-footer {
        padding: 3rem 1.2rem 0;
    }
}
@media only screen and (min-width: 1170px){
.vertical-timeline.vertical-timeline--two-columns:before {
    left: 50%;
    margin-left: -2px;
    background-color: #147350;
}}
@media(max-width:321px){
    .modalCloseBtn button{
      position: relative;
      margin-left: 1.8rem;
  }
  .modal-story-body{
    padding-left: 0rem;
    margin-left: 0rem;
  }
  .modal-body-container{
    margin-right: 2rem;
    margin-left: 0;
    padding-left: 0;
    padding-top: 2.2rem;
    padding-bottom: 1rem;
}
.modal-body-container h1{
    padding-left: 2rem;
}
.brandstory-img img {
    width: auto;
    height: 14rem;
    margin: -2rem 0 2rem;
}
.about-nav {
    font-size: 20px;
}
.brandstory-vision {
    padding: 0 1rem 1rem;
}
.brandstory-img {
    margin: 0 1rem 0 1rem;
}
} 

.carousel-wrapper {
    position: relative;
    width: 100%;
    height: calc(100vh - 110px);
}

.carousel-wrapper img,
.carousel-wrapper video {
    width: 100%;
    height: calc(100vh - 110px);
    object-fit: cover;
}

.carousel-wrapper .MuiIconButton-root {
    top: calc(50% + 19.5rem) !important;
    opacity: 0.6;
    background-color: transparent;
}

.carousel-wrapper .MuiIconButton-root:hover {
    background-color: transparent;
}

.carousel-wrapper .css-8ucetn-MuiButtonBase-root-MuiIconButton-root {
    color: #147350;
}

.carousel-indicators {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.carousel-indicators > li {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
}

.carousel-indicators > li.active {
    background-color: #147350;
}

.carousel-overlay {
    position: absolute;
    text-align: center;
    background-color: transparent;
    box-shadow: none;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
    color: white;
}

.carousel-overlay h2 {
    font-size: 2rem;
    text-shadow: 3px 3px 6px black;

}
